
export const SanitizerMixin = {

    methods: {
        
        sanitizeFilename(filename) {
            const invalidCharsRegex = /[<>:"/\\|?*]/g;

            let sanitizeFilename = filename.replace(invalidCharsRegex, '_');
            sanitizeFilename = sanitizeFilename.trim();

            if (sanitizeFilename.length > 255) {
                sanitizeFilename = sanitizeFilename.substring(0, 255);
            }

            return sanitizeFilename;
        },

    },
}
