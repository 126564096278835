<template>
    <div>
        <sui-modal
            size="mini"
            id="subtaskStateChangeModal"
            :open="isOpen"
            v-on:clickAwayModal="clickAway()"
        >
            <sui-modal-header>
                {{ modalTitle }}
            </sui-modal-header>
            <sui-modal-content scrolling v-if="JSON.stringify(activeSubtask) != '{}'">
                <sui-form :loading="subtaskModalLoading">
                    <sui-form-field inline v-if="JSON.stringify(activeSubtask) != '{}'">
                        <label>Ansvarlig <small><em>(planlægning)</em></small>: <span v-if="activeSubtaskResponsible"> {{activeSubtaskResponsible.Initials}}</span></label>
                        <i class="fa-kit fa-nisse" v-if="specialOccasion == 'christmas' && activeSubtaskResponsible && activeSubtaskResponsible.Email == this.user.email"></i>
                        <i class="fas fa-user-hard-hat" v-else-if="activeSubtaskResponsible && activeSubtaskResponsible.Email == this.user.email"></i>
                        <br>
                        <sui-dropdown
                            v-if="this.userHasLeadPermissions"
                            placeholder="Medarbejder"
                            selection
                            :options="availableWorkersPlusNone"
                            v-model="activeSubtaskResponsibleEmail"
                        />
                        <sui-button
                            v-else
                            type="button"
                            @click="takeResponsibility"
                        >
                            <span v-if="activeSubtask.Responsible && activeSubtask.Responsible.Email == this.user.email">Fjern ansvar</span>
                            <span v-else>Tag ansvar</span>
                        </sui-button>
                    </sui-form-field>
                    <sui-form-field inline v-if="JSON.stringify(activeSubtask) != '{}'">
                        <label style="vertical-align: top">Gøres på dagen for installation:</label>
                        <sui-checkbox toggle v-model="activeSubtaskDeferred"/>
                    </sui-form-field>
                    <hr v-if="JSON.stringify(activeSubtask) != '{}'" />
                    <sui-form-field>
                        <sui-button
                            size="tiny"
                            @click.prevent="documentationErrorReportModalOpen = true"
                        >
                            Rapportér fejl i dokumentation
                        </sui-button>
                    </sui-form-field>
                    <hr/>
                    <sui-form-field inline key="subtaskValueFormField">
                        <label style="vertical-align: top">{{activeSubtask.titles.inputLabel}}</label>
                        <sui-checkbox v-if="activeSubtask && activeSubtask.state && activeSubtask.state.valueType == 'bool'" toggle v-model="activeSubtaskValue" :disabled="subtaskShouldBeDisabled(activeSubtask, firebaseSubTasks)"/>
                        <sui-form-field inline v-if="activeSubtask && activeSubtask.state && activeSubtask.state.valueType == 'num'">
                            <input placeholder="0" type="number" min="0" max="3000" v-model="activeSubtaskValue" :disabled="subtaskShouldBeDisabled(activeSubtask, firebaseSubTasks)"/> &nbsp; <label>m</label>
                        </sui-form-field>
                    </sui-form-field>
                    <sui-form-field v-if="this.activeSubtaskValue">
                        <label>Udført af <small><em>(Reel, en eller flere medarbejdere)</em></small></label>
                        <sui-dropdown
                            v-if="this.userHasLeadPermissions"
                            placeholder="Medarbejder(e)"
                            selection
                            multiple
                            :options="availableWorkers"
                            v-model="activeSubtaskCompletedBy"
                        />
                    </sui-form-field>
                    <!-- <sui-form-field>
                        <sui-button type="submit" positive>Gem</sui-button>
                    </sui-form-field> -->
                </sui-form>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button 
                    negative
                    @click.prevent="clickAway"
                >
                    Luk
                </sui-button>
                <sui-button 
                    positive
                    @click.prevent="updateSubtask"
                >
                    Gem
                </sui-button>
            </sui-modal-actions>
        </sui-modal>

        <documentation-error-report-modal
            v-if="JSON.stringify(activeSubtask) != '{}' && isOpen"
            :isOpen="documentationErrorReportModalOpen"
            :task="activeInst.tasks.find((t) => t.code == 'BLAES') || findFirstTask(activeInst.tasks)"
            :installationLabel="instLabel"
            :availableWorkers="this.project ? this.project.Workers || [] : []"
            :project="project"
        />
    </div>
</template>
<script>
import swal from 'sweetalert'
import { SubtaskMixin } from '../../lib/SubtaskMixin'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import { db, FieldPath, FieldValue, analytics } from '../../firebase'
import EventBus from '../../EventBus'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import DocumentationErrorReportModal from '@/components/Project/DocumentationErrorReportModal'
import UserRoles from '../../lib/Enums/UserRoles'
import { specialOccasionsMixin } from '../../lib/Mixins/specialOccasionsMixin'

export default {
    name: 'SubtaskStateChangeForm',
    mixins: [SubtaskMixin, Mixin, DateMixin, DataAPI, unitWorkMixin, specialOccasionsMixin],
    enums: {UserRoles},
    components: {DocumentationErrorReportModal},
    classes: [FieldPath],
    
    props: {
        isOpen: Boolean,
        modalTitle: String,
        activeSubtask: Object,
        instLabel: String,
        project: Object,
        workerData: Array,
        subtaskModalLoading: Boolean,
        firebaseSubTasks: Array,
        user: Object,
        activeInst: Object,
        ignoreLeadPermissions: Boolean,
        activeSubtaskValueInput: Boolean
    },

    data() {
        return {
            activeSubtaskValue: null,
            activeSubtaskResponsible: null,
            activeSubtaskResponsibleEmail: null,
            activeSubtaskDeferred: false,
            activeSubtaskCompletedBy: [],

            documentationErrorReportModalOpen: false,

            firebaseUser: null
        }
    },

    computed: {
        userHasLeadPermissions() {
            if (this.ignoreLeadPermissions) return false
            if(this.firebaseUser && this.firebaseUser.Roles && this.firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR)) return true //Project editors always have lead permissions
            let userContact = this.project.Contacts.find(contact => contact.Email == this.user.email) //Find user in project contacts
            if (!userContact) return false //if user is not in project contacts, they cannot be project lead
            if (userContact.Roles.includes('ProjectLead')) return true //Allow lead permissions for project lead
            return false
        },
        availableWorkers() {
            let workerArray = []
            for (let worker of this.workerData) {
                if (!worker || !worker.Email) continue;
                workerArray.push({
                    ...worker,
                    value: worker.Email,
                    text: `${worker.Name} (${this.getInitialsFromEmail(worker.Email)})`,
                })
            }
            return workerArray
        },
        availableWorkersPlusNone() {
            return [...this.availableWorkers, {
                value: 'Ingen',
                text: 'Ingen',
            }]

        },
        unitWorkAllUnits() {
            return [
                ...this.unitWorkCompositeUnits || [],
                ...this.unitWorkUnits || [],
            ]
        },
    },

    methods: {
        clickAway(){
            EventBus.$emit('subtask-state-change-modal-closing')
        },

        prePopulateForm() {
            this.activeSubtaskValue = this.activeSubtaskValueInput
            if (JSON.stringify(this.activeSubtask) != "{}"){
                console.log('populating form...')
                this.activeSubtaskValue = this.activeSubtask.state.value
                this.activeSubtaskDeferred = this.activeSubtask.Deferred?.Value
                this.activeSubtaskResponsibleEmail = this.activeSubtask.Responsible ? this.activeSubtask.Responsible.Email : null
                // if (this.activeSubtask.Responsible && this.availableWorkers) {
                //     this.activeSubtaskResponsibleEmail = this.availableWorkers.find(worker => {
                //         if (!worker) return false
                //         console.log(worker.email?.toLowerCase(),this.activeSubtask.Responsible?.Email?.toLowerCase())
                //         return worker.email?.toLowerCase() == this.activeSubtask.Responsible?.Email?.toLowerCase()
                //     })
                // }
                if (this.activeSubtask.state.doneBy) {
                    console.log(this.activeSubtask.state.doneBy)
                    let splitDoneBy = this.activeSubtask.state.doneBy.split(", ")
                    console.log(splitDoneBy)
                    let doneByWorkersArray = []
                    for (let name of splitDoneBy){
                        let doneByWorker = this.workerData.find(w => w ? `${w.firstname} ${w.lastname}` == name : false)
                        if (doneByWorker) doneByWorkersArray.push(doneByWorker.email)
                    }
                    this.activeSubtaskCompletedBy = doneByWorkersArray
                } else {
                    this.activeSubtaskCompletedBy = [this.user.email]
                }
            }
            
        },

        takeResponsibility() {
            if (!this.activeSubtask.Responsible || this.activeSubtask.Responsible.Email != this.user.email) { //If the current user is not already responsible, make him/her
                let responsibleObj = {
                    Name: this.user.displayName,
                    Email: this.user.email,
                    Initials: this.getInitialsFromEmail(this.user.email),
                    ResponsibilityTakenAt: this.formatMachineTimestamp(new Date())
                }
                this.activeSubtaskResponsible = responsibleObj
                // console.log(this.activeSubtask)
            } else { //If the current user IS already responsible, remove the responsibility
                var tempSubtask = this.activeSubtask
                delete tempSubtask.Responsible
                this.activeSubtaskResponsible = tempSubtask.Responsible
            }
        },

        async updateSubtask(){
            EventBus.$emit('function-activity', {functionName: 'SubtaskStateChangeForm_updateSubtask', isActive: true})
            // this.subtaskModalLoading = true
            EventBus.$emit('subtaskModalLoading',true)
            let docref = db.collection(`InternalSubTasks`).doc(this.instLabel)

            if (!this.activeInst.tasks[0]?.configurationItem?.area?.sonWinProjectId && this.project.AreaCodes != 1) {
                swal('Fejl', 'Installation mangler område nr.\nKan ikke gemme data.', 'error')
                EventBus.$emit('function-activity', {functionName: 'SubtaskStateChangeForm_updateSubtask', isActive: false})
                return
            }

            let AreaCode = this.activeInst.tasks[0].configurationItem.area.sonWinProjectId
            if (!AreaCode && this.project.AreaCodes && this.project.AreaCodes.length == 1) { //In case we don't have the area code, see if it can be guessed from the project
                AreaCode = this.project.AreaCodes[0]
            }

            if (!this.activeInst.tasks[0]?.project?.type?.value && this.project.Type.length != 1) {
                swal('Fejl', 'Installation mangler projekt type værdi (Eftertilmelding, Projekttilslutning, konvertering).\nKan ikke gemme data.', 'error')
                EventBus.$emit('function-activity', {functionName: 'SubtaskStateChangeForm_updateSubtask', isActive: false})
                return
            }

            let ProjectInstallationType = this.activeInst?.tasks?.[0]?.project?.type?.value
            if (!ProjectInstallationType && this.project.Type.length == 1) { //In case we don't have the project type, see if it can be guessed from the project
                ProjectInstallationType = this.project.Type[0]
            } //TODO: Add more ways to try to infer the project type

            let updateDoc = {
                InstallationLabel: this.instLabel,
                LinkedProjects: FieldValue.arrayUnion(this.project.id),
                ProjectInstallationType,
                AreaCode: this.activeInst.tasks[0].configurationItem.area.sonWinProjectId
            }

            let blowingUpdateDoc = {}
            let splicingUpdateDoc = {}
            let patchingUpdateDoc = {}

            let updateShouldSendToFirebase = false
            let now = new Date()
            let subtaskCompletedByNamesArray = []
            if (this.activeSubtaskValue) {
                console.log(this.activeSubtaskCompletedBy, this.workerData)
                for (let email of this.activeSubtaskCompletedBy) {
                    let workerData = this.workerData.find(w => w ? w.email == email : false)
                    if (workerData) subtaskCompletedByNamesArray.push(`${workerData.firstname} ${workerData.lastname}`)
                }
            }
            let subtaskCompletedByNames = subtaskCompletedByNamesArray.join(", ")
            if (subtaskCompletedByNames == "") subtaskCompletedByNames = this.user.displayName || this.user._delegate.displayName

            let firBlowingObj = this.firebaseSubTasks.find(subtask => subtask.code == "BLOW")
            let firSplicingObj = this.firebaseSubTasks.find(subtask => subtask.code == "SPLICE")
            let firPatchingObj = this.firebaseSubTasks.find(subtask => subtask.code == "PATCH")

            let unitWorkDocument = {
                AreaCode: this.activeInst.tasks[0].configurationItem?.area?.sonWinProjectId,
                ProjectInstallationType: this.activeInst.tasks[0].project?.type?.value,
                PayedBy: 'EFB',
                ConfigurationItem: {
                    Label: this.activeInst.tasks[0].configurationItem?.label,
                    Area: this.activeInst.tasks[0].configurationItem?.area,
                    Cabinet: this.activeInst.tasks[0].configurationItem?.cabinet,
                    TechnicalData: this.activeInst.tasks[0].configurationItem?.technicalData,
                    Type: this.activeInst.tasks[0].configurationItem?.type,
                    Value: this.activeInst.tasks[0].configurationItem?.value,
                    Address: this.formatAddress(this.activeInst.tasks[0].configurationItem?.address, false) || null,
                },
                ReferenceId: this.activeInst.tasks[0].referenceId,
                Workers: this.activeSubtaskCompletedBy || [this.user.email],
                TimeStamp: new Date(),
                CreatedBy: {
                    Name: this.user.displayName,
                    Email: this.user.email,
                    Initials: this.getInitialsFromEmail(this.user.email)
                },
                AutoGenerated: true,
                Date: this.formatMachineDate(new Date(), '-'),
                Billed: {
                    Bool: false,
                    Updated: new Date()
                },
                LinkedProjects: [this.$route.params.projectIdentifier],
                FromDocumentation: {},
            }

            switch (this.activeSubtask.code){
                case "BLOW": 

                    if (this.activeSubtaskValue != null && this.activeSubtaskValue != firBlowingObj.state?.value) {     
                        blowingUpdateDoc["BlownAt"] = this.formatMachineTimestamp(now)
                        blowingUpdateDoc["BlownBy"] = subtaskCompletedByNames
                        blowingUpdateDoc["BlownData"] = {} //Should be a snapshot of the documentation at the time of subtask completion
                        blowingUpdateDoc["Length"] = Number(this.activeSubtaskValue)

                        updateShouldSendToFirebase = true

                        unitWorkDocument.Unit = {
                            Id: this.unitWorkAllUnits.find((u) => u.id == '930').id,
                            ...this.unitWorkAllUnits.find((u) => u.id == '930')
                        }
                        unitWorkDocument.Amount = Number(this.activeSubtaskValue)
                        unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, Number(this.activeSubtaskValue))
                        unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, Number(this.activeSubtaskValue))

                        let unitWorkForInst = await this.dataGetUnitWorkForInst(this.activeInst.label)
                        let foundUnitWork = unitWorkForInst.find(unitWork => unitWork.Unit.Id == '930')
                        if (this.activeSubtaskValue) {
                            if (foundUnitWork) {
                                swal(`Der er allerede skrevet ${foundUnitWork.Amount} ${foundUnitWork.Unit.Unit} ${foundUnitWork.Unit.Name} for denne installation, vil du tilføje enhed igen?`, {
                                    buttons: {
                                        cancel: 'Nej',
                                        hours: 'Noter timeløns-arbejde',
                                        yes: 'Ja',
                                    }
                                }).then((value) => {
                                    if (value) {
                                        if (value == 'yes') {
                                            this.dataAddOrUpdateUnitWork(unitWorkDocument)
                                        } else {
                                            EventBus.$emit('should-open-unitWork-modal',{description: `Omblæsning af ${foundUnitWork.Amount}m fiber`}) //TODO: Estimate hours based on unitWork equivalent hours
                                        }
                                    }
                                })
                            } else {
                                this.dataAddOrUpdateUnitWork(unitWorkDocument)
                            }
                        } else if (foundUnitWork) {
                            swal(`Der er skrevet ${foundUnitWork.Amount} ${foundUnitWork.Unit.Unit} ${foundUnitWork.Unit.Name} for denne installation, vil du slette den enhed?`, {
                                buttons: ['Nej', 'Ja'],
                                dangerMode: true,
                            }).then(value => {
                                if(value){
                                    this.dataDeleteUnitWork(foundUnitWork).then(() => {
                                        swal('Enhed blev slettet', `Følgende enhed blev slettet:\n\n${foundUnitWork.Amount} ${foundUnitWork.Unit.Unit} ${foundUnitWork.Unit.Name}`,'success')
                                    }).catch((error) => {
                                        swal('Fejl i sletning',error,'error')
                                    })
                                }
                            })
                        }
                    }

                    if (this.activeSubtaskResponsible?.Email != firBlowingObj.Responsible?.Email){
                        blowingUpdateDoc["Responsible"] = this.activeSubtaskResponsible || null
                        updateShouldSendToFirebase = true
                    }

                    if (this.activeSubtaskDeferred != null && this.activeSubtaskDeferred != firBlowingObj.Deferred?.Value) {
                        blowingUpdateDoc["Deferred"] = {
                            Value: this.activeSubtaskDeferred,
                            DefferedBy: this.user.displayName,
                            DeferredAt: this.formatMachineTimestamp(now)
                        }
                        updateShouldSendToFirebase = true
                    }

                    break;
                case "SPLICE": 

                    if (this.activeSubtaskValue != null && this.activeSubtaskValue != firSplicingObj.state?.value) {
                        splicingUpdateDoc["SplicedAt"] = this.formatMachineTimestamp(now)
                        splicingUpdateDoc["SplicedBy"] = subtaskCompletedByNames
                        splicingUpdateDoc["SplicedData"] = {} //Should be a snapshot of the documentation at the time of subtask completion
                        splicingUpdateDoc["Spliced"] = Boolean(this.activeSubtaskValue)

                        updateShouldSendToFirebase = true

                        unitWorkDocument.Unit = {
                            Id: this.unitWorkAllUnits.find((u) => u.id == '931.F.04').id,
                            ...this.unitWorkAllUnits.find((u) => u.id == '931.F.04')
                        }
                        unitWorkDocument.Amount = Number(this.activeSubtaskValue)
                        unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, Number(this.activeSubtaskValue))
                        unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, Number(this.activeSubtaskValue))

                        let unitWorkForInst = await this.dataGetUnitWorkForInst(this.activeInst.label)
                        let foundUnitWork = unitWorkForInst.find(unitWork => unitWork.Unit.Id == '931.F.04')
                        if (this.activeSubtaskValue) {
                            if (foundUnitWork) {
                                swal(`Der er allerede skrevet ${foundUnitWork.Amount} ${foundUnitWork.Unit.Unit} ${foundUnitWork.Unit.Name} for denne installation, vil du tilføje enhed igen?`, {
                                    buttons: {
                                        cancel: 'Nej',
                                        hours: 'Noter timeløns-arbejde',
                                        yes: 'Ja',
                                    }
                                }).then((value) => {
                                    if (value) {
                                        if (value == 'yes') {
                                            this.dataAddOrUpdateUnitWork(unitWorkDocument)
                                        } else {
                                            EventBus.$emit('should-open-unitWork-modal',{description: `Gen-splidsning af ${foundUnitWork.Amount} fiberpar`})
                                        }
                                    }
                                })
                            } else {
                                this.dataAddOrUpdateUnitWork(unitWorkDocument)
                            }
                        } else if (foundUnitWork) {
                            swal(`Der er skrevet ${foundUnitWork.Amount} ${foundUnitWork.Unit.Unit} ${foundUnitWork.Unit.Name} for denne installation, vil du slette den enhed?`, {
                                buttons: ['Nej', 'Ja'],
                                dangerMode: true,
                            }).then(value => {
                                if(value){
                                    this.dataDeleteUnitWork(foundUnitWork).then(() => {
                                        swal('Enhed blev slettet', `Følgende enhed blev slettet:\n\n${foundUnitWork.Amount} ${foundUnitWork.Unit.Unit} ${foundUnitWork.Unit.Name}`,'success')
                                    }).catch((error) => {
                                        swal('Fejl i sletning',error,'error')
                                    })
                                }
                            })
                        }
                    }

                    if (this.activeSubtaskResponsible?.Email != firSplicingObj.Responsible?.Email) {
                        splicingUpdateDoc["Responsible"] = this.activeSubtaskResponsible
                        updateShouldSendToFirebase = true
                    }

                    if (this.activeSubtaskDeferred != null && this.activeSubtaskDeferred != firSplicingObj.Deferred?.Value) {
                        splicingUpdateDoc["Deferred"] = {
                            Value: this.activeSubtaskDeferred,
                            DefferedBy: this.user.displayName,
                            DeferredAt: this.formatMachineTimestamp(now)
                        }
                        updateShouldSendToFirebase = true
                    }

                    break;
                case "PATCH": 

                    if (this.activeSubtaskValue != null && this.activeSubtaskValue != firPatchingObj.state?.value) {
                        patchingUpdateDoc["PatchedAt"] = this.formatMachineTimestamp(now)
                        patchingUpdateDoc["PatchedBy"] = subtaskCompletedByNames
                        patchingUpdateDoc["PatchedData"] = {} //Should be a snapshot of the documentation at the time of subtask completion
                        patchingUpdateDoc["Patched"] = Boolean(this.activeSubtaskValue)

                        updateShouldSendToFirebase = true

                        let unitWorkForInst = await this.dataGetUnitWorkForInst(this.activeInst.label)
                        let foundUnitWork = unitWorkForInst.filter(unitWork => (unitWork.Unit.Id == '931.F.01' || unitWork.Unit.Id == '931.F.02' || unitWork.Unit.Id == '931.F.03'))
                        let unitList = foundUnitWork.reduce((acc, cur) => {
                                    let curString = `${cur.Amount} ${cur.Unit.Unit} ${cur.Unit.Id}: ${cur.Unit.Name}`
                                    if (!acc) return curString
                                    return `${acc}\n${curString}`
                                }, '')

                        if (this.activeSubtaskValue) {
                            unitWorkDocument.Unit = {
                                Id: this.unitWorkAllUnits.find((u) => u.id == '931.F.01').id, //Opmærkning af patchkabel
                                ...this.unitWorkAllUnits.find((u) => u.id == '931.F.01')
                            }
                            unitWorkDocument.Amount = 2
                            unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, 2)
                            unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, 2)
    
                            if (!foundUnitWork.find(unitWork => unitWork.Unit.Id == '931.F.01')){
                                await this.dataAddOrUpdateUnitWork(unitWorkDocument)
                            }
    
                            unitWorkDocument.Unit = {
                                Id: this.unitWorkAllUnits.find((u) => u.id == '931.F.02').id, //Patch af datafiber
                                ...this.unitWorkAllUnits.find((u) => u.id == '931.F.02')
                            }
                            unitWorkDocument.Amount = 1
                            unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, 1)
                            unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, 1)
    
                            if (!foundUnitWork.find(unitWork => unitWork.Unit.Id == '931.F.02')){
                                await this.dataAddOrUpdateUnitWork(unitWorkDocument)
                            }
    
                            unitWorkDocument.Unit = {
                                Id: this.unitWorkAllUnits.find((u) => u.id == '931.F.03').id, //Patch af TV-fiber
                                ...this.unitWorkAllUnits.find((u) => u.id == '931.F.03')
                            }
                            unitWorkDocument.Amount = 1
                            unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, 1)
                            unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, 1)
    
                            if (!foundUnitWork.find(unitWork => unitWork.Unit.Id == '931.F.03')){
                                await this.dataAddOrUpdateUnitWork(unitWorkDocument)
                            }

                            if (foundUnitWork.length) {
                                swal(`Der er allerede skrevet enheds-arbejde på for følgende enheder:\n\n${unitList}\n\nDe skrives ikke på igen.`, {
                                    buttons: {
                                        cancel: 'OK',
                                        hours: 'Notér timeløns-arbejde'
                                    }
                                }).then((value) => {
                                    if (value) {
                                        EventBus.$emit('should-open-unitWork-modal',{description: `Ompatchning i teknikhus`})
                                    }
                                })
                            }
                        } else if (foundUnitWork) {
                            swal(`Der er skrevet følgende enheder på denne installation:\n\n${unitList}\n\nVil du slete dem?`, {
                                buttons: ['Nej', 'Ja'],
                                dangerMode: true,
                            }).then(value => {
                                if(value){
                                    this.dataDeleteUnitWork(foundUnitWork).then(() => {
                                        swal('Enhed blev slettet', `Følgende enheder blev slettet:\n\n${unitList}`,'success')
                                    }).catch((error) => {
                                        swal('Fejl i sletning',error,'error')
                                    })
                                }
                            })
                        }

                    }

                    if (this.activeSubtaskResponsible?.Email != firPatchingObj.Responsible?.Email) {
                        patchingUpdateDoc["Responsible"] = this.activeSubtaskResponsible
                        updateShouldSendToFirebase = true
                    }

                    if (this.activeSubtaskDeferred != null && this.activeSubtaskDeferred != firPatchingObj.Deferred?.Value) {
                        patchingUpdateDoc["Deferred"] = {
                            Value: this.activeSubtaskDeferred,
                            DefferedBy: this.user.displayName,
                            DeferredAt: this.formatMachineTimestamp(now)
                        }
                        updateShouldSendToFirebase = true
                    }

                    break;
            }
            try {
                if (updateShouldSendToFirebase) {
                    await docref.update(updateDoc)
                    console.log(updateDoc)

                    let promises = []
                    if (Object.keys(blowingUpdateDoc).length){
                        let keys = Object.keys(blowingUpdateDoc)
                        for (let i = 0; i < keys.length; i++){
                            let key = keys[i]
                            let fieldPath = new FieldPath("Blowing",key)
                            promises.push(docref.update(fieldPath,blowingUpdateDoc[key]))
                        }
                        // promises.push(docref.update("Blowing",blowingUpdateDoc))
                    }
                    if (Object.keys(splicingUpdateDoc).length){
                        let keys = Object.keys(splicingUpdateDoc)
                        for (let i = 0; i < keys.length; i++){
                            let key = keys[i]
                            let fieldPath = new FieldPath("Splicing",key)
                            promises.push(docref.update(fieldPath,splicingUpdateDoc[key]))
                        }
                        // promises.push(docref.update("Splicing",splicingUpdateDoc))
                    }
                    if (Object.keys(patchingUpdateDoc).length){
                        let keys = Object.keys(patchingUpdateDoc)
                        for (let i = 0; i < keys.length; i++){
                            let key = keys[i]
                            let fieldPath = new FieldPath("Patching",key)
                            promises.push(docref.update(fieldPath,patchingUpdateDoc[key]))
                        }
                        // promises.push(docref.update("Patching",patchingUpdateDoc))
                    }
                    await Promise.all(promises)

                    updateDoc.Blowing = blowingUpdateDoc
                    updateDoc.Splicing = splicingUpdateDoc
                    updateDoc.Patching = patchingUpdateDoc

                    // EventBus.$emit('reload-subtask-status-icons', updateDoc.InstallationLabel)
                    console.log('Updated firebase subtask with data: ',updateDoc)

                    analytics.logEvent('subtask_update', updateDoc)

                } else {
                    console.log('Nothing to update, request not sent')
                }
            }
            catch(err){
                console.log(err)
                try{
                    updateDoc.Blowing = blowingUpdateDoc
                    updateDoc.Splicing = splicingUpdateDoc
                    updateDoc.Patching = patchingUpdateDoc
                    await docref.set(updateDoc)
                    console.log('created document with data:', updateDoc)
                    // EventBus.$emit('reload-subtask-status-icons', updateDoc.InstallationLabel)
                }
                catch(error) {
                    console.error(error)
                }
            }
            this.showSubtaskModal = false //Close Modal
            // this.activeSubtask = {} //Clear Modal //DO NOT MUTATE PROPERTY DIRECTLY
            this.activeSubtaskValue = null //Clear Modal Form
            // this.subtaskModalLoading = false //End loading animation
            EventBus.$emit('subtaskModalLoading',false)
            EventBus.$emit('subtaskStateChangeFormSubmit')

            this.$parent.closePrjTaskIfSubtaskComplete(this.activeSubtaskDeferred)
            EventBus.$emit('function-activity', {functionName: 'SubtaskStateChangeForm_updateSubtask', isActive: false})
        },
    },

    watch: {
        activeSubtask: {
            immediate: true,
            handler() {
                this.prePopulateForm()
            }
        },
        activeSubtaskResponsibleEmail: {
            immediate: true,
            handler(activeSubtaskResponsibleEmail) {
                if(activeSubtaskResponsibleEmail == null) {
                    this.activeSubtaskResponsible = null
                    return
                }
                let worker = this.availableWorkers.find(w => w ? w.Email == activeSubtaskResponsibleEmail : false)
                if (worker && worker.text != "Ingen") {
                    let workerEmail = worker.Email?.toLowerCase()
                    let responsibleObj = {
                        Email: workerEmail,
                        Initials: worker.Initials,
                        Name: worker.Name,
                        ResponsibilityTakenAt: this.formatMachineTimestamp(new Date())
                    }
                    this.activeSubtaskResponsible = responsibleObj
                } else {
                    this.activeSubtaskResponsible = null
                }
            }
        },
        user: {
            immediate: true,
            handler(user) {
                // this.$unbind('firebaseUser')
                if (user){
                    this.$bind('firebaseUser', db.collection(`Users`).doc(user.email))
                }
            }
        },
    },

    beforeMount() {
        EventBus.$on('documentation-error-report-modal-closing', () => {
            this.documentationErrorReportModalOpen = false
        })
    }

}
</script>