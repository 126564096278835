<template>
    <i :class="[icons[currentIcon], size]"></i>
</template>
<script>

export default {
    name: 'ScottyBeamMeUp',

    props: {
        size: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            icons: [
                'fa-solid fa-transporter',
                'fa-solid fa-transporter-1',
                'fa-solid fa-transporter-2',
                'fa-solid fa-transporter-3',
                'fa-solid fa-transporter-4',
                'fa-solid fa-transporter-5',
                'fa-solid fa-transporter-6',
                'fa-solid fa-transporter-7',
                'fa-solid fa-transporter-empty',
            ],
            currentIcon: 0,
        }
    },

    

    mounted() {
        // Start the sequence
        this.startIconSequence();
    },

    beforeDestroy() {
        // Stop the sequence
        this.stopIconSequence();
    },

    methods: {
        startIconSequence() {
            setInterval(() => {
                this.currentIcon = (this.currentIcon + 1) % this.icons.length;
            }, 500); // Change icon every second
        },

        stopIconSequence() {
            clearInterval(this.startIconSequence);
        },
    }
}
</script>
<style scoped>


</style>

