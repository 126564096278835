<template>
    <div>
        <sui-modal
            :open="isOpen"
            @close="clickAway"
            size="small"
        >
            <sui-modal-header>
                Vælg en signaturmetode
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70vh;">
                <sui-form>
                    <label>Underskriver</label>
                    <sui-list style="margin-top: -3px; margin-bottom: 25px;">
                        <sui-list-item><b :class="classes(!signerName && 'red-text')">Navn:</b> {{ signerName }}</sui-list-item>
                        <sui-list-item><b :class="classes(!signerEmail && 'red-text')">Email:</b> {{ signerEmail }}</sui-list-item>
                        <sui-list-item><b :class="classes(!signerPhone && 'red-text')">Mobil:</b> {{ signerPhone }}</sui-list-item>
                    </sui-list>
                    <div v-if="!verifySigner" style="margin-bottom: 10px;">
                        <sui-message>
                            <!-- <sui-message-header>Fejl i undersk</sui-message-header> -->
                            <p>Der mangler oplysninger på underskriver. <br> Underskriftsprocessen kan ikke fortsætte.</p>
                        </sui-message>
                    </div>
                    <sui-divider section />
                    <label>Signaturmetode</label>
                    <div class="form-field-spacing" v-for="option in signOptions" :key="option.text"> 
                        <sui-form-field>
                            <sui-checkbox radio
                                name="signMethod" 
                                :label="option.text" 
                                :value="option.value" 
                                v-model="selectedSignMethod"
                            />
                        </sui-form-field>
                    </div>

                    <label></label>
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <sui-checkbox 
                                :disabled="disableEmailOptions"
                                label="Send e-mail når dokumentet er underskrevet" 
                                v-model="sendEmailIfSigned"
                            ></sui-checkbox>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <sui-checkbox 
                                :disabled="disableEmailOptions"
                                label="Send e-mail vis dokumentet er blevet afvist eller udløbet" 
                                v-model="sendEmailIfNotSigned"
                            ></sui-checkbox>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <sui-checkbox 
                                :disabled="disableAutoUpload"
                                label="Upload automatisk når dokumentet er underskrevet" 
                                v-model="autoUploadOnSign"
                            ></sui-checkbox>
                        </sui-form-field>
                    </div>
                </sui-form>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button
                    @click="clickAway"
                >
                    Annuller
                </sui-button>
                <sui-button
                    :disabled="disableConfirm"
                    @click="confirmSignMethod"
                    primary
                >
                    <i v-if="awaitingCompletion" class="fa-solid fa-spinner-scale fa-spin-pulse"></i>
                    <span v-else>Bekræft</span>
                </sui-button>
            </sui-modal-actions>
    
        </sui-modal>

    </div>
</template>

<script>
    // Import mixins
    import { DataAPI } from '@/lib/DataAPI.js'

    // Import enums
    import FormToPDFDtate from '../enums/FormToPDFState.js'
    import EmailTemplate from '@/lib/Enums/EmailTemplate.js'

    // Import cloud functions
    import { cloudFunctions } from '@/firebase.js'


    export default {

        mixins: [
            DataAPI,
        ],

        enums: {
            FormToPDFDtate,
            EmailTemplate,
        },

        props: {
            isOpen: {
                type: Boolean,
                required: true
            },
            activeForm: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                selectedSignMethod: 'portal',
                signOptions: [
                    { text: 'Underskriv via kundeportal', value: 'portal' },
                    { text: 'Underskriv på skærmen (nu)', value: 'screen' },
                    { text: 'Underskriv på papir', value: 'paper' },
                ],

                // Flags
                sendEmailIfSigned: true,
                sendEmailIfNotSigned: true, // Rejected or expired
                autoUploadOnSign: true,

                // Signer
                signerEmail: '',
                signerName: '',
                signerPhone: '',

                portalBaseUrl: 'http://localhost:4200/kunde/dokument',

                awaitingCompletion: false,
            }
        },

        computed: {
            user() {
                return this.$root.$children[0].user
            },

            firebaseUser() {
                return this.$root.$children[0].firebaseUser
            },

            disableEmailOptions() {
                return this.selectedSignMethod != 'portal';
            },

            disableAutoUpload() {
                return this.selectedSignMethod == 'paper';
            },

            verifySigner() {
                return this.signerEmail && this.signerName && this.signerPhone;
            },

            disableConfirm() {
                return !this.verifySigner;
            }
        },

        methods: {
            clickAway(mode = 'shallow') {
                this.signerEmail = '';
                this.signerName = '';
                this.signerPhone = '';

                this.selectedSignMethod = 'portal';
                this.sendEmailIfSigned = true;
                this.sendEmailIfNotSigned = true;
                this.autoUploadOnSign = true;

                this.$emit('close', mode);
            },

            updateSigner() {
                for (let componentGroup of this.activeForm.formComponentGroups){
                    for (let component of componentGroup.components){
                        if (component?.data?.signerEmail){
                            this.signerEmail = component.data.signerEmail;
                        }
                        if (component?.data?.signerName){
                            this.signerName = component.data.signerName;
                        }
                        if (component?.data?.signerPhone){
                            this.signerPhone = component.data.signerPhone;
                        }
                    }
                }
            },

            prePopulateForm() {
                if (!this.isOpen) return;
                this.updateSigner();
            },

            async confirmSignMethod() {
                this.awaitingCompletion = true;
                console.log('Active form', this.activeForm);

                let signLedgerDocument = {
                    signMethod: this.selectedSignMethod,
                    sendEmailIfSigned: this.sendEmailIfSigned,
                    sendEmailIfNotSigned: this.sendEmailIfNotSigned,
                    autoUploadOnSign: this.autoUploadOnSign,
                    linkedProjects: this.activeForm.linkedProjects,
                    linkedForm: this.activeForm.id,
                    documentId: 'Find this somehow',
                    documentHash: 'Find this somehow',
                    status: 'pending',
                    statusUpdated: new Date().toISOString(),
                    rejectMessage: '',
                    signerEmail: this.signerEmail,
                    signerName: this.signerName,
                    signerPhone: this.signerPhone,
                }

                console.log('Sign ledger document', signLedgerDocument);
                let response = await this.dataAddSignLedgerEntry(signLedgerDocument);
                console.log('Response', response);

                let signLedgerEntries = this.activeForm.signLedgerEntries || [];
                signLedgerEntries.push(response.id);
                let formUpdate = {
                    // state: FormToPDFDtate.AWAITING_APPROVAL,
                    // stateUpdatedAt: new Date().toISOString(),
                    signLedgerEntries: signLedgerEntries,
                }
                console.log('Form update', formUpdate);
                this.dataAddOrUpdateFormToPdfForm(this.activeForm.id, formUpdate);

                if (this.selectedSignMethod == 'portal'){
                    console.group('Sign via portal');
                    console.log('Create final pdf for signing');
                    console.log('Creating sign ledger document');
                    console.log('Updating form status and lock for changes');
                    console.log('Send e-mail to customer');
                    console.groupEnd();

                    // await adding session token (cloud function)
                    try {
                        let session = await cloudFunctions.httpsCallable('addSessionTokenForFirebaseDocument')({
                            firebaseCollection: 'SignLedgers', 
                            documentId: response.id, 
                            ttl: 24 * 60 * 60 * 1000, // 24 hours
                        });

                        // then update portal url with token
                        let portalUrl = `${this.portalBaseUrl}?id=${response.id}&token=${session.data.sessionTokenWithExpiry}`;
                        console.log('Portal url (used for email)', portalUrl);

                        // portalUrl = 'http://' + portalUrl;
                        this.dataAddEmail({
                            to: this.signerEmail,
                            bcc: '',
                            template: {
                                name: EmailTemplate.FORM_TO_PDF_SIGN_INSPECTION,
                                data: {
                                    formName: this.activeForm.documentTitle,
                                    link: portalUrl,
                                }
                            }
                        });

                        console.log("Active form", this.activeForm);


                    } catch (error) {
                        console.error('Error code:', error.code);
                        console.error('Error message:', error.message);
                        console.error('Error details:', error.details);
                    }
                }

                if (this.selectedSignMethod == 'screen'){
                    console.group('Sign on screen');
                    console.log('Create final pdf for signing');
                    console.log('Creating sign ledger document');
                    console.log('Updating form status and lock for changes');
                    console.log('Open signature pad and await signature');
                    console.groupEnd();
                }

                if (this.selectedSignMethod == 'paper'){
                    console.group('Sign on paper');
                    console.log('Create final pdf for signing');
                    console.log('Creating sign ledger document');
                    console.log('Updating form status and lock for changes');
                    console.log('Print document and await signature');
                    console.groupEnd();
                }

                this.awaitingCompletion = false;
                this.clickAway('deep');
            }
        },

        watch: {
            isOpen: {
                handler(open){
                    if (open) {
                        this.prePopulateForm()
                    }
                }
            },
        }
    }

</script>

<style scoped>
    .form-field-spacing {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .red-text {
        color: red;
    }
</style>