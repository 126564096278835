export const specialOccasionsMixin = {

    computed: {
        specialOccasion() {
            let today = new Date()

            let specialOccasions = [
                {className: 'christmas', startDate: new Date(today.getFullYear(), 10, 24), endDate: new Date(today.getFullYear(), 11, 26)},
                {className: 'star-trek-day', startDate: new Date(today.getFullYear(), 8, 8), endDate: new Date(today.getFullYear(), 8, 9)},
            ]

            //Return the first special occasion that is currently happening
            let currently = specialOccasions.find(occasion => {
                return today >= occasion.startDate && today <= occasion.endDate
            })
            return currently ? currently.className : ''
        }
    },

}
