<template>
    <div class="loading-wrapper">
        <div :class="overlay ? 'loading-overlay' : ''">
            <div v-if="active" style="text-align: center;">
                <i class="fa-duotone fa-solid fa-spinner-third fa-spin fa-2xl" style="--fa-animation-duration: 1s;" :style="iconColors"></i>
                <div style="margin-top: 7px;" :style="textColor"> 
                    {{ content }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'LoadingSpinner',

    props: {
        active: {
            type: Boolean,
            default: true
        },
        content: {
            type: String,
            default: 'Loading...'
        },
        overlay: {
            type: Boolean,
            default: false
        },
        primaryColor: {
            type: String,
            default: '#3e4e69'
        },
        secondaryColor: {
            type: String,
            default: '#a4abb8'
        }
    },

    computed: {
        iconColors() {
            return `--fa-primary-color: ${this.primaryColor}; --fa-secondary-color: ${this.secondaryColor};`
        },

        textColor() {
            return `color: ${this.primaryColor};`
        },
    }
}
</script>
<style scoped>
/*.loading-wrapper {
    transform: rotate(0deg);
}
*/
.ui.loader {
    position: relative !important;
    margin-top: 4.5rem !important;
}

/* .loading-overlay {
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(234 234 234 / 80%);
} */
</style>
