<template>
    <div>
        <sui-modal
            id="viewChangeLogModal"
            :open="isOpen"
            v-on:clickAwayModal="clickAway()"
        >
            <sui-modal-header>
                Redigeringshistorik for {{ getDocumentType() }}
                <sui-item-meta style="font-size: small"> Dokument Id: {{ docId }} </sui-item-meta>
            </sui-modal-header>
            <sui-modal-content scrolling v-if="document && changeLog" style="max-height: 70vh">
                <sui-table striped>
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3">
                                Metadata
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell>{{ getDocumentType('Dokument') }} oprettet {{ getCreatedTimestamp() }} af {{ getCreatedBy() }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>{{ getDocumentType('Dokument') }} sidst redigeret {{ getUpdatedTimestamp() }} af {{ getUpdatedBy() }}</sui-table-cell>
                        </sui-table-row>
            
                        <sui-table-row>
                            <sui-table-cell v-if="document.metadata && document.metadata.documentContainsPII && document.metadata.documentContainsPII == true" style="color: red">Dette dokument indeholder GDPR følsomme oplysninger</sui-table-cell>
                            <sui-table-cell v-else-if="document.metadata && document.metadata.documentContainsPII && document.metadata.documentContainsPII == false" style="color: green">Dette dokument indeholder ikke GDPR følsomme oplysninger</sui-table-cell>
                            <sui-table-cell v-else style="color: orange">Det vides ikke om dette dokument indeholder GDPR følsomme oplysninger</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped v-for="log in changeLog" :key="log.date">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3">
                                Ændringer foretaget {{ toUserFriendlyTimestamp(log.date) }} af {{ log.user.displayName }}
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell colspan="1">
                                <b>Variabel navn</b>
                            </sui-table-cell>
                            <sui-table-cell colspan="1">
                                <b>Gammel værdi</b>
                            </sui-table-cell>
                            <sui-table-cell colspan="1">
                                <b>Ny værdi</b>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-for="entry in log.changes" :key="entry.path">
                            <sui-table-cell v-if="entry.type && entry.type == 'note'"> Note </sui-table-cell>

                            <sui-table-cell v-else> 
                                {{ entry.friendlyFieldName }} 
                                <small><br> {{ entry.path }} </small>
                            </sui-table-cell>
                            <sui-table-cell v-if="entry.type && entry.type == 'note'" colspan="2"> {{ entry.noteBody }} </sui-table-cell>

                            <sui-table-cell v-else> {{ entry.old }} </sui-table-cell>
                            <sui-table-cell v-if="!entry.type"> {{ entry.new }} </sui-table-cell>
                        </sui-table-row>
                        
                    </sui-table-body>
                </sui-table>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button @click="clickAway()">Luk</sui-button>
            </sui-modal-actions>
        </sui-modal>
    </div>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import EventBus from '../../EventBus.js'

export default {
    mixins: [Mixin, DateMixin],

    props: {
        isOpen: Boolean,
        docId: String,
        document: Object,
    },

    data(){
        return {
            changeLog: []
        }
    },

    methods: {

        clickAway(){
            EventBus.$emit('view-change-log-modal-closing')
        },

        getDocumentType(defaultVal = "'ukendt dokument type'"){
            if (this.document?.metadata?.documentType) return this.document.metadata.documentType.friendlyFieldName
            return defaultVal
        },

        getCreatedTimestamp(defaultVal = "'ukendt'"){
            if (this.document?.metadata?.created) return this.toUserFriendlyTimestamp(this.document.metadata.created, false)
            return defaultVal
        },

        getUpdatedTimestamp(defaultVal = "'ukendt'"){
            if (this.document?.metadata?.updated) return this.toUserFriendlyTimestamp(this.document.metadata.updated, false)
            return defaultVal
        },

        getCreatedBy(defaultVal = "'ukendt'"){
            if (this.document?.metadata?.createdBy) return this.document.metadata.createdBy.displayName
            return defaultVal
        },

        getUpdatedBy(defaultVal = "'ukendt'"){
            if (this.document?.metadata?.updatedBy) return this.document.metadata.updatedBy.displayName
            return defaultVal
        },

    },

    watch: {
        isOpen: {
            immediate: false,
            handler(isOpen) {
                if (isOpen){
                    let tempLog = this.cloneJson(this.document.changeLog)
                    tempLog.reverse()
                    this.changeLog = tempLog
                }
            }
        }   
    }
}
</script>

<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }
</style>