<template>
    <div style="overflow: auto; width: 100vw;">
        
        <sign-in-component
            :state="state"
            collectionName="SignLedgers"
            mainStateName="main"
            @updateState="updateState"
        ></sign-in-component>


        <div v-if="state=='main'" style="height: 100svh;"> 
            <div style="background-color: white;">
                <sui-container style="display: flex; justify-content: space-between; align-items: center;">
                    <div style="float: left;">
                        <img class="main-logo" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth-dev.appspot.com/o/assets%2FFiberLAN_logo.svg?alt=media&token=229c6da0-c5a7-4b4b-938f-120ba435f6b2" />
                    </div>
                
                    <div style="float: right;">
                        

                        <sui-button 
                            class="fiberlan-button-white" 
                            style="margin-right: 10px; padding: 9.87755px"
                            @click="signOut()"
                        > <i class="fa-solid fa-right-from-bracket fa-xl" ></i> </sui-button>
                    </div>
                </sui-container>
                <div>
            </div>
            </div>

            <sui-container text-align="left" v-if="subState == 'preview'">
                <DataCard style="margin-top: 20px;">
                    <div style="height: 20px; margin-top: 0px; margin-bottom: 10px;">
                        <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fda.svg?alt=media&token=b6f74453-c309-4a38-974e-c1a2516fdabe" @click="changeLang('da')"/>
                        <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fen.svg?alt=media&token=563007c2-7298-430d-a390-29d5a453581f" @click="changeLang('en')"/>
                    </div>
                    <div v-if="lang=='da'">
                        <h3>Velkommen til FiberLAN's Elektroniske Underskriftsportal</h3>
                        <p>Vi gør det nemt og sikkert for dig at underskrive dine dokumenter elektronisk. Følg blot disse trin for at gennemføre processen:</p>
                        <ol>
                            <li><span style="font-family: 'FiberLAN Bold';">Gennemgå Dokumentet:</span> Før du underskriver, anbefaler vi, at du læser dokumentet grundigt igennem for at sikre, at du forstår og er enig med indholdet.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Elektronisk Underskrift:</span> Når du er klar, kan du underskrive dokumentet elektronisk ved at følge anvisningerne på skærmen.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Bekræftelse:</span> Når du har underskrevet vil du modtage en bekræftelse, og en kopi af det underskrevne dokument vil blive sendt til din e-mail.</li>
                        </ol>
                        <h4>Sikkerhed og Fortrolighed</h4>
                        <p>Vi prioriterer din sikkerhed og fortrolighed. Alle data bliver behandlet med højeste grad af sikkerhed og fortrolighed, og din digitale underskrift bliver beskyttet gennem avancerede krypteringsteknologier.</p>
                        
                        <h4>Spørgsmål eller Problemer?</h4>
                        <p>Har du spørgsmål eller oplever du problemer med at underskrive dokumentet? Kontakt vores kundeservice på +45 70 70 70 70</p>
                    </div>
                    <div v-if="lang=='en'">
                        <h3>Welcome to FiberLAN's Electronic Signature Portal</h3>
                        <p>We make it easy and secure for you to sign your documents electronically. Simply follow these steps to complete the process:</p>
                        <ol>
                            <li><span style="font-family: 'FiberLAN Bold';">Review the Document:</span> Before signing, we recommend that you read the document carefully to ensure that you understand and agree with the content. The document is only provided in danish.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Electronic Signature:</span> When you are ready, you can sign the document electronically by following the on-screen instructions.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Confirmation:</span> Once you have signed, you will receive a confirmation, and a copy of the signed document will be sent to your email.</li>
                        </ol>
                    </div>

                    <div style="display: flex; justify-content: right; margin-bottom: 15px; margin-top: 15px;">
                        <sui-button 
                            v-if="lang=='da'"
                            class="fiberlan-button" 
                            circular 
                            @click="onReviewClick()"
                        > Gennemgå Dokumentet </sui-button>

                        <sui-button 
                            v-if="lang=='en'"
                            class="fiberlan-button" 
                            circular
                            @click="onReviewClick()"
                        > Review Document </sui-button>
                    </div>
                </DataCard>
                <div style="height: 10px !important;"></div>
            </sui-container>

            <sui-container text-align="left" v-if="subState == 'review' || subState == 'reject' || subState == 'sign'">
                <DataCard style="margin-top: 20px;">
                    <div style="height: 20px; margin-top: 0px; margin-bottom: 10px;">
                        <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fda.svg?alt=media&token=b6f74453-c309-4a38-974e-c1a2516fdabe" @click="changeLang('da')"/>
                        <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fen.svg?alt=media&token=563007c2-7298-430d-a390-29d5a453581f" @click="changeLang('en')"/>
                    </div>

                    <adobe-embed-pdf v-if="pdfReady" :openFile="pdfFile" :openFileIsURL="true" :height="pdfViewportHeight"/>

                    <sui-form style="margin-top: 20px;">
                        <sui-form-field>
                            <sui-checkbox 
                                style="font-family: 'FiberLAN Regular';"
                                :label="aknowledgeLabel"
                                v-model="enableSignOrReject"
                            ></sui-checkbox>
                        </sui-form-field>

                        <sui-form-field v-if="subState == 'reject'">
                            <sui-message style="margin-top: 10px; font-family: FiberLAN Regular;" v-if="lang == 'da'">
                                <sui-message-header style="font-family: FiberLAN Regular;"> Du har valgt at afvise at underskrive dokumentet </sui-message-header>
                                Beskriv kort årsagen til afvisning nedenfor, samt ønskede ændringer til dokumentet inden underskrift. <br>
                                Du kan også vælge at annullere afvisningen ved at klikke på knappen "Annuller" nedenfor.
                            </sui-message>

                            <sui-message style="margin-top: 10px; font-family: FiberLAN Regular;" v-if="lang == 'en'">
                                <sui-message-header style="font-family: FiberLAN Regular;"> You have chosen to reject signing the document </sui-message-header>
                                Briefly describe the reason for rejection below, as well as desired changes to the document before signing. <br>
                                You can also choose to cancel the rejection by clicking the "Cancel" button below.
                            </sui-message>

                            <textarea
                                style="font-family: 'FiberLAN Regular';"
                                rows="4"
                                :placeholder="rejectMessageLabel"
                                v-model="rejectMessage"
                            ></textarea>
                        </sui-form-field>
                    </sui-form>

                    <div style="display: flex; justify-content: right; margin-bottom: 15px; margin-top: 15px;">
                        <sui-button 
                            v-if="subState == 'review'"
                            class="fiberlan-button-inverted" 
                            circular 
                            style="margin-right: 10px;"
                            :disabled="!enableSignOrReject"
                            @click="onRejectClick()"
                        > {{ rejectButtonLabel }} </sui-button>

                        <sui-button 
                            v-if="subState == 'review'"
                            class="fiberlan-button" 
                            circular 
                            :disabled="!enableSignOrReject"
                            @click="openSignaturePad()"
                        > {{ signButtonLabel }} </sui-button>
                        
                        <sui-button 
                            v-if="subState == 'reject'"
                            class="fiberlan-button-inverted" 
                            circular 
                            style="margin-right: 10px;"
                            @click="updateSubstate('review')"
                        > {{ cancelLabel }} </sui-button>
                        
                        <sui-button 
                            v-if="subState == 'reject'"
                            class="fiberlan-button" 
                            circular 
                            :disabled="rejectMessageIsEmtpy"
                        > {{ sendRejectLabel }} </sui-button>
                    </div>
                </DataCard>
                <div style="height: 10px !important;"></div>

                <on-screen-signature-pad
                    :isOpen="signaturePadIsOpen"
                    @close="closeSignaturePad"
                ></on-screen-signature-pad>

            </sui-container>
        </div>

    </div>
</template>

<script>
// import { auth } from '../../../firebase'
import { db, storageRef } from '../../../firebase'
// import EventBus from '../../../EventBus'
// import swal from 'sweetalert'
import AdobeEmbedPdf from '@/components/Project/AdobePdfEmbed.vue'
import SignInComponent from '@/pages/Public/CustomerPortal/SignInComponent.vue';

import { cloudFunctions } from '@/firebase.js'
import OnScreenSignaturePad from '@/modules/FormToPdf/components/OnScreenSignaturePad.vue';

export default {
    name: 'DocumentApproval',

    mixins: [
        db,
        storageRef,
    ],

    components: {
        AdobeEmbedPdf,
        SignInComponent,
        OnScreenSignaturePad,
    },

    data() {
        return {
            lang: 'da',
            signLedgerDocId: '',
            sessionToken: '',
            accessToken: '',

            verificationCode: '',

            state: 'request', // request, verify, notFound, expired, main
            subState: 'preview', // preview, review, sign, reject

            pdfFile: {
                name: "test.pdf", 
                url: null
            },
            pdfReady: false,
            enableSignOrReject: false,
            rejectMessage: '',
            signaturePadIsOpen: false,
        }
    },

    computed: {
        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        pdfViewportHeight() {
            return this.currentMedia.type === 'mobile' ? '100vh' : '65vh'
        },

        rejectButtonLabel() {
            return this.lang === 'da' ? 'Afvis' : 'Reject';
        },

        signButtonLabel() {
            return this.lang === 'da' ? 'Underskriv Dokument' : 'Sign Document';
        },

        rejectMessageLabel() {
            return this.lang === 'da' ? 'Beskriv kort årsagen til afvisning' : 'Briefly describe the reason for rejection';
        },

        aknowledgeLabel() {
            return this.lang === 'da' ? 'Jeg har læst og forstået dokumentet' : 'I have read and understood the document';
        },

        rejectMessageIsEmtpy() {
            return this.rejectMessage === '';
        },

        sendRejectLabel() {
            return this.lang === 'da' ? 'Send Afvisning' : 'Send Rejection';
        },

        cancelLabel() {
            return this.lang === 'da' ? 'Annuller' : 'Cancel';
        },
    },

    methods: {

        updateState(newState) {
            this.state = newState;
            if (newState === 'main') {
                window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-main-page');
            }
        },

        updateSubstate(newSubstate) {
            this.subState = newSubstate;
        },

        changeLang(lang) {
            this.lang = lang
            const url = new URL(window.location);
            url.searchParams.set('lang', this.lang);
            window.history.pushState({}, '', url);
        },

        setAccessToken(token) {
            this.accessToken = token;
            const url = new URL(window.location);
            url.searchParams.set('access', token);
            window.history.pushState({}, '', url);
        },

        onReviewClick() {
            this.updateSubstate('review');
            this.verifySmsCode();
        },

        onRejectClick() {
            this.updateSubstate('reject');
        },

        async verifySmsCode() {
            let apiDoc = await db.collection('API').doc('PilotBI').get();
            this.$store.commit('setAdobeClientId', apiDoc.data().AdobeClientId);
            await this.getDocument();
            this.pdfReady = true;
        },

        async getDocument() {
            let docRef = await storageRef.child('FormToPdf/TestEFB/FiberLAN A_S - Kærvej 39.pdf')
            let downloadURL = await docRef.getDownloadURL()
            let metadata = await docRef.getMetadata()
            console.log("Document metadata:", metadata)
            this.pdfFile.url = downloadURL
            this.pdfFile.name = metadata.name
        },

        async signOut() {
            try {
                const url = new URL(window.location); // Create a URL object from the current URL
                url.searchParams.delete("access"); // Remove the access token from the URL
                
                // Update the browser's URL without reloading the page
                window.history.replaceState(null, null, url.toString());
                this.updateState('signout');
                window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-login-page');
                
                await cloudFunctions.httpsCallable('logoutCustomerPortal')({
                    firebaseCollection: 'SignLedgers', 
                    documentId: this.signLedgerDocId, 
                    sessionToken: this.sessionToken,
                });
            } catch (error) {
                console.error('Error code:', error.code);
                console.error('Error message:', error.message);
                console.error('Error details:', error.details);
            }
        },

        openSignaturePad() {
            this.updateSubstate('sign');
            this.signaturePadIsOpen = true;
        },

        closeSignaturePad(deep = false) {
            this.signaturePadIsOpen = false;
            if (deep) {
                this.updateSubstate('review');
            }
        },
    },

    beforeMount() {
        if (this.state === 'main'){
            window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-main-page');
        } else {
            window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-login-page');
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(newVal) {
                if (newVal.query.lang) {
                    this.lang = newVal.query.lang;
                } else {
                    this.lang = 'da';
                }

                if (newVal.query.id) {
                    this.signLedgerDocId = newVal.query.id;
                }

                if (newVal.query.token) {
                    this.sessionToken = newVal.query.token;
                }

                if (newVal.query.access) {
                    this.accessToken = newVal.query.access;
                    if (this.accessToken){
                        this.updateState('main');
                    }

                }
            }
        },
    },
}
</script>
<style scoped>
    h3 {
        font-family: 'FiberLAN Bold';
        color: black;
    }

    h4 {
        font-family: 'FiberLAN Bold';
        color: black;
    }

    p {
        font-family: 'FiberLAN Regular';
    }

    li {
        font-family: 'FiberLAN Regular';
        margin-bottom: 7px;
    }

    .login-box {
        align-content: center;
        height: 100svh;
        margin-left: auto;
        margin-right: auto;
    }
    img.logo {
        width: 100%;
    }

    img.main-logo {
        margin-top: 10px;
        margin-bottom: 10px;
        min-width: 150px !important;
        width: 15%;
    }

    img.lang-flag {
        float: right;
        width: 10%;
        max-width: 30px !important;
        margin-left: 10px;
    }

    img.lang-flag:hover {
        cursor: pointer;
        transform: scale(1.125);
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
</style>
<style>
    .customer-portal-login-page {
        /* background-color: #ccb19d; */
        background-image: url('https://fiberlan.dk/wp-content/uploads/2023/04/FiberLAN-lokationer-4-1.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        background-color: var(--fiberlan-orange-light);
    }

    .customer-portal-main-page {
        /* background-color: #ccb19d; */
        background-image: url('https://fiberlan.dk/wp-content/uploads/2023/04/FiberLAN-SOL-kobber.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        /* background-size: cover; */
        background-position: center;
        background-color: white;
    }
</style>
