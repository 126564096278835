<template>
    <div>
        <sui-modal
            id="viewFormPdfModal"
            :open="isOpen"
        >
            <sui-modal-header v-if="form">
                {{ form.documentTitle }}
                <sui-button
                    size="mini"
                    title="Annullér"
                    floated="right"
                    style="padding: 9.87755px; margin-left: 10px;" 
                    @click.prevent="clickAway()"
                ><i class="fa-solid fa-xmark"></i></sui-button>
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70svh;" v-if="form">
         
                 <loading-spinner 
                    content="Genererer PDF på serveren..." 
                    :active="!pdfReady"
                />
  
                <adobe-embed-pdf v-if="pdfReady" :openFile="pdfFileReference" :openFileIsURL="true"/>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button
                    size="mini"
                    style="padding: 9.87755px;"
                    title="Gem PDF i vores database"
                    @click="onSave()"
                ><i class="fa-solid fa-cloud-arrow-up"></i></sui-button>

                <sui-button
                    size="mini"
                    style="padding: 9.87755px;"
                    title="Download PDF"
                    @click="onSave()"
                ><i class="fa-solid fa-arrow-down-to-line"></i></sui-button>

                <sui-button
                    size="mini"
                    style="padding: 9.87755px;"
                    title="Exportér PDF og gem i Virkplan"
                    @click="onSave()"
                ><i class="fa-solid fa-file-export"></i></sui-button>


            </sui-modal-actions>
        </sui-modal>
    </div>
    
</template>

<script>
import EventBus from '@/EventBus'

import { storageRef } from '@/firebase.js'

import { pdfGeneratorMixin } from '@/modules/FormToPdf/mixins/PdfGeneratorMixin.js'
import { Mixin } from '@/lib/Mixins/mixin'
import { DateMixin } from '@/lib/Mixins/dateMixin'

import FormToPdfComponents from '@/modules/FormToPdf/enums/FormToPdfComponents'
import AdobeEmbedPdf from '@/components/Project/AdobePdfEmbed.vue'

import { cloudFunctions } from '@/firebase'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { SanitizerMixin } from '@/lib/helpers/sanitizers.js'

export default {
    mixins: [
        pdfGeneratorMixin,
        Mixin,
        DateMixin,
        SanitizerMixin
    ],

    enums: {
        FormToPdfComponents
    },

    components: {
        AdobeEmbedPdf,
        LoadingSpinner,
    },

    props: {
        isOpen: Boolean,
        form: {
            type: Object,
            required: false,
        },
        //Property 'inputContent' is an array passed from parent component
        inputContent: {
            type: Array,
            required: false,
        },
        dynamicPageBreak: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data() {
        return {
            pdfFileReference: {
                name: "untitled.pdf", 
                url: null
            },
            pdfReady: false,

            pdfVersion: 1,

            pdfDefinition: {
                content: [],
                images: {
                    backgroundImage: 'FormToPdfAssets/Stjerne til brevpapir.jpg'
                    // fiberlanLogo: 'https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2FSvendborgAfdeling.JPG?alt=media&token=93baab32-c3cf-4f27-8c38-3d362bc3ef47',
                    // portraitImage: 'https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2FSk%C3%A6rme%20Sydbank%20Slagelse.JPG?alt=media&token=7a108a73-36a8-4135-a6cb-575c117501c4',
                },
            },
        };
    },
    computed: {
        user() {
            return this.$root.$children[0].user
        },
        pdfMeta() {
            return {
                contentType: 'application/pdf',
                customMetadata: { //Custom metadata for uploads to Firebase storage MUST be a simple object with no nesting
                    title: this.form.documentTitle,
                    createdBy: this.user.email,
                    deleteBy: new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString(), //Delete file after 6 months
                    linkedForm: this.form.id,
                    state: this.form.state,
                    signed: this.form.signed,
                }
            }
        },
    },
    methods: {
        clickAway(deep = true){

            this.state = "opening"
            this.subState = "loading"
            this.selectedFormTemplate = null
            this.currentForm = null

            if (deep){
                EventBus.$emit('event-view-pdf-form-modal-closing')
            }
        },

        uploadFile() {
            let fileName = `${this.form.documentTitle}_${new Date().toISOString()}`
            let newFileRef = storageRef.child(`FormToPdf/${this.form.id}/Files/${fileName}.pdf`)
            newFileRef.put(this.pdfFileBlob, this.pdfMeta).then((snapshot) => {
                console.log('Uploaded a blob or file!', snapshot)
            });
        },

        async prePopulateForm() {
            // Code to pre-populate the form with data from the database
            console.log('Pre-populating form')

            let pdfDocContent = []

            for (let group of this.form.formComponentGroups){
                for (let component of group.components){
                    console.log("component type", component.componentType)

                    let pdfOutput = null
                    
                    if (component.componentType === FormToPdfComponents.IMAGE_LIST){
                        let stackOfStacks = await this.executeFunction(FormToPdfComponents.getGeneratePdfMethodFromCode(component.componentType), component)
                        for (let stack of stackOfStacks.stack){
                            pdfDocContent.push(stack)
                        }
                    } else {
                        pdfOutput = await this.executeFunction(FormToPdfComponents.getGeneratePdfMethodFromCode(component.componentType), component)
                        pdfDocContent.push(pdfOutput)
                    }
                }
            }

            this.$set(this.pdfDefinition, 'content', pdfDocContent)

            let filename = `FormToPdf/${this.form.id}/Files/${this.sanitizeFilename(this.form.documentTitle)}_preview.pdf`;
            
            cloudFunctions.httpsCallable('createPdfFromDefinition')({
                pdfContent: pdfDocContent, 
                metaData: this.pdfMeta.customMetadata, 
                filePath: filename, 
                dynamicPageBreak: this.dynamicPageBreak, 
                images: this.pdfDefinition.images
            }).then((result) => {
                console.log(result)
                this.pdfFileReference.name = this.form.documentTitle + '.pdf'
                this.pdfFileReference.url = result.data.result[0] //AAAAAAH
                this.pdfReady = true
            })
        },
    },
    mounted() {
        // Code to run when the component is mounted

        console.log('PDF Generator mounted')

        
    },

    watch: {
        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        },
    }
};
</script>

<style scoped>

</style>