<template>
    <div>
        <sui-modal
            size="small"
            id="editImageModal"
            :open="isOpen"
        >
            <sui-modal-header>
                {{ modalHeader }}
                <sui-button
                    class="square-icon-button modal-close-button"
                    :class="currentMedia.type === 'Mobile' ? 'mobile' : ''"
                    size="mini"
                    title="Annullér"
                    floated="right"
                    @click="clickAway()"
                ><i class="fa-solid fa-xmark"></i></sui-button>
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70svh;">
                <sui-form>
                    <!-- Canvas Section -->
                    <div class="canvas-container">
                        <canvas 
                            ref="canvas" 
                            class="check-image" 
                            @mousedown="onMouseDown"
                            @mousemove="onMouseMove"
                            @mouseup="onMouseUp"
                            @touchstart="onMouseDown"
                            @touchmove="onMouseMove"
                            @touchend="onMouseUp"
                        ></canvas>
                    </div>

                    <!-- Message for missing image -->
                    <sui-message 
                        v-if="!imageFound && image"
                    >
                        <sui-message-header>Billedet blev ikke fundet</sui-message-header>
                        <p>
                            Prøv at uploade billedet igen eller vælg et andet billede
                        </p>
                    </sui-message>

                    <!-- File upload -->
                    <div class="button-slider" v-if="!showDrawTools"> 

                        <sui-button
                            size="mini"
                            class="actions-buttons"
                            title="Upload eller tag billede med telefonens kamera"
                            @click.prevent="triggerFileInput"
                            >
                            <i class="fa-solid fa-camera"></i>
                            Upload eller tag billede
                        </sui-button> 
                        <input ref="embedFileInput" type="file" hidden @change="setFileUpload"/>
                        
                        <sui-button
                            size="mini"
                            class="actions-buttons"
                            title="Vælg billede fra biblioteket af allerede uploadede billeder"
                            @click.prevent="openImageSelectorModal()"
                        >
                            <i class="fa-solid fa-images"></i>
                            Vælg fra bibliotek
                        </sui-button> 
                            
                        <sui-button
                            size="mini"
                            class="actions-buttons"
                            title="Rediger billedet"
                            @click.prevent="showDrawTools = true"
                        >
                            <i class="fa-solid fa-sliders"></i>
                            Rediger
                        </sui-button> 
                    </div>

                    <!-- Draw tools -->
                    <div class="button-slider" v-if="showDrawTools">
                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'select' }"
                            size="mini"
                            title="Vælg"
                            @click.prevent="selectTool('select')"
                        >
                            <i class="fa-solid fa-arrow-pointer"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            size="mini"
                            title="Fortryd (Ctrl + Z)"
                            @click.prevent="undoDraw()"
                        >
                            <i class="fa-solid fa-arrow-turn-left"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            size="mini"
                            title="Annuller fortryd (Ctrl + Y)"
                            @click.prevent="redoDraw()"
                        >
                            <i class="fa-solid fa-arrow-turn-right"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'freehand', 'secondary': drawMode == 'select' && selectedObject && selectedObject.type == 'freehand' }"
                            size="mini"
                            title="Frihåndstegning"
                            @click.prevent="selectTool('freehand')"
                        >
                            <i class="fa-solid fa-pen-swirl"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'line', 'secondary': drawMode == 'select' && selectedObject && selectedObject.type == 'line' }"
                            size="mini"
                            title="Linje"
                            @click.prevent="selectTool('line')"
                        >
                            <i class="fa-solid fa-pen-line"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'textbox', 'secondary': drawMode == 'select' && selectedObject && selectedObject.type == 'textbox' }"
                            size="mini"
                            title="Tekstboks"
                            @click.prevent="selectTool('textbox')"
                        >
                            <i class="fa-solid fa-text"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'x', 'secondary': drawMode == 'select' && selectedObject && selectedObject.type == 'x' }"
                            size="mini"
                            title="Indsæt kryds"
                            @click.prevent="selectTool('x')"
                        >
                            <i class="fa-solid fa-xmark-large"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'o', 'secondary': drawMode == 'select' && selectedObject && selectedObject.type == 'o' }"
                            size="mini"
                            title="Indsæt cirkel"
                            @click.prevent="selectTool('o')"
                        >
                            <i class="fa-solid fa-o"></i>
                        </sui-button> 
                    </div>

                    <!-- Tool options -->
                    <div v-if="showPenToolOptions">
                        <i 
                            v-for="(color, name) in colorOptions" 
                            :key="name" 
                            class="fa-duotone fa-regular fa-square" 
                            :class="drawColor == color ? 'fa-2xl' : 'fa-xl'"
                            style="--fa-primary-opacity: 1; --fa-secondary-opacity: 1;"
                            :style="`--fa-secondary-color: ${ color };`"
                            @click="drawColor = color"
                        ></i>
                    </div>
                    <div v-if="showPenToolOptions" style="margin-top: 7px;"> 
                        <input type="range" v-model="lineWidth" min="1" max="50" />
                    </div>

                        
                

                    <!-- Form options -->
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <label> Hvordan skal billedet vises i PDF'en? </label>
                            <sui-dropdown
                                selection
                                search
                                :options="renderingOptions"
                                v-model="renderingOption"
                            ></sui-dropdown>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <sui-checkbox 
                                label="Inkluder billede i PDF" 
                                v-model="includeInPdf"
                            ></sui-checkbox>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing">
                        <sui-form-field>
                            <label> Billedtekst </label>
                            <textarea 
                                placeholder="Beskriv billedet" 
                                v-model="imageText" 
                            ></textarea>
                        </sui-form-field>
                    </div>
                                   

                </sui-form>
            </sui-modal-content>

            <sui-modal-actions>
                <sui-button
                    class="square-icon-button"
                    positive 
                    size="mini"
                    title="Gem billedet"
                    @click.prevent="onSave()"
                ><i class="fa-solid fa-floppy-disk"></i></sui-button>

                <sui-button
                    class="square-icon-button"
                    negative
                    size="mini"
                    title="Slet billedet"
                    @click.prevent="onDelete()"
                    :disabled="deleteButtonDisabled"
                ><i class="fa-solid fa-trash"></i></sui-button>
            </sui-modal-actions>

        </sui-modal>
       
        <image-selector-modal
            :isOpen="imageSelectorModalOpen"
            :baseFilePath="imageSelectorBaseFilePath"
            @select-image="selectImage"
            @close="closeImageSelectorModal"
        />
    </div>                             
</template>

<script>
import EventBus from '@/EventBus'
import swal from 'sweetalert'

import { storageRef } from '@/firebase'
import { JSON2HTML } from '@/modules/FormToPdf/mixins/JSON2HTMLMixin.js'
import { Mime } from '@/lib/helpers/mime.js'
import ImageSelectorModal from '@/components/Images/ImageSelectorModal.vue'
    
export default {

        mixins: [
            // Mixin,
            Mime,
            EventBus,
            JSON2HTML,
        ],

        components: {
            ImageSelectorModal
        },

        props: {
            isOpen: Boolean,
            form: Object,
            image: Object,
            indices: Object,
            path: String,
        },

        data() {
            return {
                imagePath: '',
                imageURL: '',
                imageText: '',
                imageFound: false,
                renderingOption: 'largeImage',
                includeInPdf: true,

                renderingOptions: [
                    { text: 'Stort billede (Billedtekst under)', value: 'largeImage' },
                    { text: 'Lille billede (Billedteskt til højre for)', value: 'smallImage' },
                    { text: 'Stort billede uden billedtekst', value: 'largeImageWithoutText' },
                ],

                // File upload
                fileFormSuccess: false,
                showProgress: false,
                fileUpload: null,

                // Image selector
                imageSelectorModalOpen: false,

                canvasImage: null,
                drawMode: null,
                drawColor: '#000000',
                lineWidth: 5,
                history: [],
                redoStack: [],
                objects: [], // Stores all drawable objects
                isDrawing: false,
                isSelecting: false,
                offsetX: 0,
                offsetY: 0,
                currentPath: [],
                selectedObject: null,
                selectionBox: null,

                showDrawTools: false,

                colorOptions: {
                    'black': '#000000',
                    'white': '#FFFFFF',
                    'red': '#FF0000',
                    'orange': '#FFA500',
                    'yellow': '#FFFF00',
                    'green': '#008000',
                    'blue': '#0000FF',
                    'purple': '#800080',
                }
            }
        },

        computed: {
            user() {
                return this.$root.$children[0].user
            },

            currentMedia(){
                return this.$root.$children[0].currentMedia
            },

            modalHeader(){
                if (this.image) {
                    return 'Rediger Billede'
                }
                return 'Tilføj Billede'
            },

            deleteButtonDisabled(){
                if (this.image) {
                    return false
                }

                if (this.imagePath || this.imageURL) {
                    return false
                }

                return true
            },

            imageSelectorBaseFilePath(){
                return `${this.path}/Images/`
            },

            showPenToolOptions(){
                if (this.drawMode == 'freehand') return true;
                if (this.drawMode == 'select' && this.selectedObject && this.selectedObject.type == 'freehand') return true;

                if (this.drawMode == 'line') return true;
                if (this.drawMode == 'select' && this.selectedObject && this.selectedObject.type == 'line') return true;

                return false;
            }
        },

        methods: {
            clickAway() {
                this.imagePath = ''
                this.imageURL = ''
                this.imageText = ''
                this.imageFound = false
                this.renderingOption = 'largeImage'
                this.includeInPdf = true
                this.fileUpload = null
                this.showDrawTools = false

                let queryImage = document.querySelector('.check-image');
                queryImage.removeEventListener('error', this.imageNotFoundHandler);

                EventBus.$emit('event-edit-image-click-away');
            },

            triggerFileInput(){
                this.$refs.embedFileInput.click()
            },

            async setFileUpload(e){
                this.fileFormSuccess = false
                this.showProgress = false
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    this.fileUpload = null
                    return false
                }
                this.fileUpload = files[0]
                this.imageURL = await this.encodeFile(this.fileUpload)
                // console.log("file", base64Content)
                return true
            },

            async onSave(){
                console.log('saving')
                
                EventBus.$emit('function-activity', { functionName: 'ImageEditorModal_onSave', isActive: true })

                if (this.fileUpload) {

                    // Upload image to storage
                    let fileName = `IMG_${new Date().toISOString()}`
                    
                    const expectedExtension = this.getExtenstionByMimeType(this.fileUpload.type)
                    const fileExtension = this.getFileExtension(fileName)
                    if (fileExtension.toLowerCase() != expectedExtension) {
                        fileName += `.${expectedExtension}`
                    }

                    let metadata = {
                        contentType: this.fileUpload.type,
                        customMetadata: { //Custom metadata for uploads to Firebase storage MUST be a simple object with no nesting
                            'uploadedBy': this.user.email
                        }
                    }

                    let base64Content = this.imageURL
                    base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)


                    let imageFilePath = `${this.path}/Images/${fileName}`
                    console.log("image file path", imageFilePath)
                    let newFileRef = storageRef.child(imageFilePath)
                    console.log("new file ref", newFileRef)
                    console.log("base64Content", base64Content);

                    let scaledImage = await this.scaleBase64Image(base64Content, 3000, 3000, expectedExtension);
                    console.log("scaledImage", scaledImage);
                    await newFileRef.putString(scaledImage, 'base64', metadata)

                    this.imagePath = imageFilePath
                    if (this.image){
                        this.imageURL = await this.getImageURL(this.imagePath)
                    }
                    this.imageFound = true
                    
                    console.log("new image to upload")
                }

                let imageData = {
                    imagePath: this.imagePath,
                    imageURL: this.imageURL,
                    imageText: this.imageText,
                    imageFound: this.imageFound,
                    renderingOption: this.renderingOption,
                    includeInPdf: this.includeInPdf,
                }

                if (this.image) {
                    EventBus.$emit('event-image-editor-update', { imageData, indices: this.indices })
                    console.log('update-image', imageData)
                } else {
                    EventBus.$emit('event-image-editor-add', { imageData, indices: this.indices })
                    console.log('add-image', imageData)
                }
                this.clickAway()

                EventBus.$emit('function-activity', { functionName: 'ImageEditorModal_onSave', isActive: false })
            },

            async onDelete(){
                let shouldDelete = await swal({
                    title: "Er du sikker?",
                    text: "Billedet vil blive slettet permanent",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })

                if (!shouldDelete) {
                    return
                }
                EventBus.$emit('event-image-editor-delete', { indices: this.indices })
                this.clickAway()
            },

            async prePopulateForm(){
                console.log("prePopulateForm")
                console.log("Image: ", this.image)
                console.log("Indices: ", this.indices)

                if (this.image) {
                    this.imagePath = this.image.imagePath
                    this.imageURL = this.image.imageURL
                    this.imageText = this.image.imageText
                    this.imageFound = this.image.imageFound
                    this.renderingOption = this.image.renderingOption
                    this.includeInPdf = this.image.includeInPdf
                } else {
                    this.imagePath = 'FormToPdfAssets/Placeholder_view_vector.jpg'
                    this.imageURL = await this.getImageURL(this.imagePath)
                }

                this.checkImages();
                // this.loadImage(this.imageURL);
            },

            async encodeFile(file){
                EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: true})
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve (reader.result)
                    reader.onerror = error => reject(error)
                })
                let encodedFile = await toBase64(file).catch(e => Error(e))
                if(encodedFile instanceof Error) {
                    console.log('Error encoding file: ',encodedFile.message)
                    EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: false})
                    return
                }
                EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: false})
                return encodedFile
            },

            openImageSelectorModal(){
                this.imageSelectorModalOpen = true
            },

            closeImageSelectorModal(){
                this.imageSelectorModalOpen = false
            },

            async selectImage(imageRef){
                this.imageURL = await imageRef.getDownloadURL()
                this.imagePath = imageRef.fullPath
            },

            async imageNotFoundHandler(){
                console.log("Image not found", this.imagePath);
                try {
                    this.imageURL = await this.getImageURL(this.imagePath);
                } catch {
                    console.log("Image not found", this.imagePath);
                    this.imageFound = false;
                }
            },

            checkImages(){
                let queryImage = document.querySelector('.check-image');
                queryImage.addEventListener('error', this.imageNotFoundHandler);
            },

            scaleBase64Image(base64Image, maxWidth, maxHeight, mimeType) {
                return new Promise((resolve, reject) => {
                    
                    // Validate base64 format
                    const base64Regex = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/i;
                    if (!base64Regex.test(base64Image)) {
                        base64Image = `data:image/${mimeType};base64,${base64Image}`;

                        // return reject(new Error("Invalid base64 image format."));
                    }

                    // Create an image element
                    const img = new Image();

                    // Set the source of the image to the base64 data
                    img.src = base64Image;

                    img.onload = () => {
                        // Get the original dimensions of the image
                        const originalWidth = img.width;
                        const originalHeight = img.height;

                        // Check if the image is already smaller than the maximum dimensions
                        if (originalWidth <= maxWidth && originalHeight <= maxHeight) {
                            // Resolve the promise with the original base64 image
                            resolve(base64Image);
                            return;
                        }

                        // Calculate the scaling factor
                        const widthRatio = maxWidth / originalWidth;
                        const heightRatio = maxHeight / originalHeight;
                        const scalingFactor = Math.min(widthRatio, heightRatio, 1); // Ensure we don't upscale

                        // Compute the new dimensions
                        const newWidth = Math.floor(originalWidth * scalingFactor);
                        const newHeight = Math.floor(originalHeight * scalingFactor);

                        // Create a canvas to resize the image
                        const canvas = document.createElement('canvas');
                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        const ctx = canvas.getContext('2d');

                        // Apply image smoothing options
                        ctx.imageSmoothingEnabled = true;
                        ctx.imageSmoothingQuality = 'high';

                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        // Get the scaled base64 image from the canvas
                        const scaledBase64 = canvas.toDataURL();

                        // Remove the MIME type prefix before resolving
                        const strippedBase64 = scaledBase64.replace(/^data:[^,]+,/, '');

                        // Resolve the promise with the scaled base64 image
                        resolve(strippedBase64);
                    };

                    img.onerror = (err) => {
                        console.error('Error loading the base64 image:', err);
                        reject(err);
                    };
                });
            },

            loadImage(url) {
                const canvas = this.$refs.canvas;
                const img = new Image();

                img.onload = () => {
                    this.canvasImage = img; // Store the loaded image in a Vue reactive property

                    // Adjust canvas dimensions to match the image dimensions
                    canvas.width = img.width;
                    canvas.height = img.height;

                    console.log("Image loaded: ", img.width, img.height);

                    // Redraw the canvas with the loaded image
                    this.redrawCanvas();
                };

                img.src = url;
            },

            keydownHandler(event) {
                const { key } = event;

                // Check the specific keys
                if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Shift", "Enter", " "].includes(key)) {
                    console.log(`Key pressed: ${key}`);
                }

                if (event.shiftKey && event.key === "ArrowRight") {
                    console.log("Shift + ArrowRight pressed!");
                    // Add your custom logic here
                }
            },

            selectTool(tool) {
                if (this.drawMode == tool) {
                    this.drawMode = null;
                    if (this.selectedObject) {
                        this.selectedObject = null;
                        this.selectionBox = null;
                        this.redrawCanvas();
                    }
                    return;
                }
                this.drawMode = tool;
                if (tool != 'select') {
                    if (this.selectedObject) {
                        this.selectedObject = null;
                        this.selectionBox = null;
                        this.redrawCanvas();
                    }
                }
                console.log("Tool selected: ", tool);
            },

            undoDraw() {
                if (this.history.length === 0) return;

                const lastDraw = this.history.pop();
                console.log("Last draw action:", lastDraw);
                if (lastDraw.type == "modify") {
                    console.log("Undoing last modification:", lastDraw);
                    console.log("undo from to", { ...lastDraw.object }, lastDraw.previousState);

                    const update = { ...lastDraw.object };

                    // Restore the object's previous state
                    Object.assign(lastDraw.object, lastDraw.previousState);
                    
                    // Push the reversed modification to the redo stack
                    this.redoStack.push({
                        type: 'modify',
                        object: lastDraw.object,
                        previousState: update, // Capture the current state for redo
                    });


                    
                    this.redrawCanvas(); // Redraw to reflect changes
                } else {
                    // Push the last draw action into the redo stack
                    this.redoStack.push(lastDraw);
                    this.redrawCanvas();
                }
               
            },

            redoDraw() {
                if (this.redoStack.length === 0) return;
                
                const lastDraw = this.redoStack.pop();
                if (lastDraw.type == "modify") {
                    console.log("Redoing last modification:", lastDraw);
                    const updatedState = { ...lastDraw.previousState };
                    console.log("redo from to", { ...lastDraw.object }, updatedState);
                    
                    // Reapply the changes to the object
                    Object.assign(lastDraw.object, updatedState);

                    // Push the redone action back to history
                    this.history.push({
                        type: 'modify',
                        object: lastDraw.object,
                        previousState: updatedState, // Capture the state before next undo
                    });


                    
                    this.redrawCanvas(); // Redraw to reflect changes
                } else {
                    // Push the last redo action back into the history
                    this.history.push(lastDraw);
                    this.redrawCanvas();
                }

                console.log("Redo completed. History:", this.history, "Redo Stack:", this.redoStack);
            },

            changeProperty(object, property, newValue) {
                if (!object) return;

                // Save the current state before making changes
                const previousState = { ...object };

                // Update the object's property
                object[property] = newValue;

                // Push the change into the history stack
                this.history.push({
                    type: 'modify',
                    object: object,
                    previousState: previousState,
                });

                // Clear the redo stack (because we're creating a new change)
                this.redoStack = [];
            },

            redrawCanvas() {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext("2d");

                // Clear the canvas
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Redraw the source image
                if (this.canvasImage) {
                    ctx.drawImage(this.canvasImage, 0, 0, canvas.width, canvas.height);
                }

                // Redraw all objects from the history
                this.history.forEach((action) => {
                    if (action.type === "freehand") {
                        this.drawPath(action.path, action.color, action.lineWidth);
                    }
                    // Handle other objects (lines, X, O, textboxes) here
                });

                // Draw the bounding box for the selected object
                this.drawSelectionBox();
            },

            onMouseDown(event) {
                const canvas = this.$refs.canvas;
                const mousePos = this.getMousePosition(canvas, event);

                if (this.drawMode == 'freehand') {
                    this.isDrawing = true;
                    this.currentPath = [mousePos];
                } else if (this.drawMode == 'select') {
                    this.selectedObject = null;
                    this.selectionBox = null;

                    for (let i = 0; i < this.history.length; i++) {
                        const action = this.history[i];

                        if (action.type === 'freehand' && this.isPointInPath(mousePos, action.path, action.lineWidth + 2)) {
                            this.selectedObject = action;
                            console.log("Selected object:", this.selectedObject);
                            this.selectionBox = this.getBoundingBox(action.path);
                            console.log("Selection box:", this.selectionBox);
                            break;
                        }
                    }
                    this.redrawCanvas();  
                }
            },

            onMouseMove(event) {
                if (this.isDrawing && this.drawMode == 'freehand') {
                    const canvas = this.$refs.canvas;
                    const mousePos = this.getMousePosition(canvas, event);
                    this.currentPath.push(mousePos);
                    this.drawPath(this.currentPath);
                }
            },

            onMouseUp() {
                if (this.isDrawing && this.drawMode == 'freehand') {
                    this.isDrawing = false;
                    this.history.push({
                        type: 'freehand',
                        path: [...this.currentPath],
                        color: this.drawColor,
                        lineWidth: this.lineWidth,
                    });
                    this.currentPath = [];
                }
            },

            getMousePosition(canvas, event) {
                if (event.type.startsWith('mouse')) {
                    const rect = canvas.getBoundingClientRect();
    
                    // Calculate scaling factors between the canvas's internal resolution and its display size
                    const scaleX = canvas.width / rect.width;
                    const scaleY = canvas.height / rect.height;
    
                    // Adjust mouse coordinates based on scaling factors
                    return {
                        x: (event.clientX - rect.left) * scaleX,
                        y: (event.clientY - rect.top) * scaleY
                    };
                }

                if (event.type.startsWith('touch')) {
                    const rect = canvas.getBoundingClientRect();
                    console.log("Touch event:", JSON.stringify(event));
                    console.log("Touch event touches:", JSON.stringify(event.touches[0]));
                    const touch = event.touches[0];

                    // Calculate scaling factors between the canvas's internal resolution and its display size
                    const scaleX = canvas.width / rect.width;
                    const scaleY = canvas.height / rect.height;

                    // Adjust touch coordinates based on scaling factors
                    return {
                        x: (touch.clientX - rect.left) * scaleX,
                        y: (touch.clientY - rect.top) * scaleY
                    };
                }

            },

            isPointInPath(point, path, tolerance = 5) {
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.beginPath();
                ctx.moveTo(path[0].x, path[0].y);
                path.forEach((p) => {
                    ctx.lineTo(p.x, p.y);
                });

                // Set a wider line width for sensitivity (adjust tolerance as needed)
                ctx.lineWidth = tolerance;

                // Check if the point is near the stroke of the path
                return ctx.isPointInStroke(point.x, point.y);
            },

            drawPath(path, color = this.drawColor, lineWidth = this.lineWidth) {
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.strokeStyle = color;
                ctx.lineWidth = lineWidth;
                ctx.beginPath();
                path.forEach((point, index) => {
                if (index === 0) {
                    ctx.moveTo(point.x, point.y);
                } else {
                    ctx.lineTo(point.x, point.y);
                }
                });
                ctx.stroke();
            },

            getBoundingBox(path) {
                let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;

                path.forEach(point => {
                minX = Math.min(minX, point.x);
                maxX = Math.max(maxX, point.x);
                minY = Math.min(minY, point.y);
                maxY = Math.max(maxY, point.y);
                });

                return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
            },

            drawSelectionBox() {
                if (!this.selectionBox) return;

                const ctx = this.$refs.canvas.getContext("2d");

                ctx.save();
                ctx.strokeStyle = "rgba(255, 0, 255, 1)"; // Magenta color with full opacity
                ctx.lineWidth = 4;
                ctx.setLineDash([5, 5]); // Dotted line

                const { x, y, width, height } = this.selectionBox;

                ctx.strokeRect(x, y, width, height); // Draw the rectangle

                ctx.restore();
            },
        },

        watch: {
            isOpen: {
                handler(open){
                    if (open) {
                        this.prePopulateForm();
                    }
                }
            },

            imageURL: {
                handler(url){
                    if (url) {
                        this.loadImage(url);
                    }
                }
            },

            drawColor: {
                handler(color){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'color', color);
                            this.redrawCanvas();
                        }
                    }
                }
            },

            lineWidth: {
                handler(width){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'lineWidth', width);
                            this.redrawCanvas();
                        }
                    }
                }
            }
        },

        mounted() {
            window.addEventListener('keydown', this.keydownHandler);
        },

        beforeDestroy() {
            window.removeEventListener('keydown', this.keydownHandler);
        },

    }
</script>

<style scoped>
    @import '@/assets/styles/buttons.css';
    .image-show-full {
        width: 700px;
        /* height: 400px; */
        /* border: 1px solid black; */
        /* margin-right: 10px; */
    }

    .image-show-full img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .form-field-spacing {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .actions-buttons {
        margin-top: 5px !important;
    }

    .canvas-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 500px; /* You can adjust this or let it be dynamic based on modal size */
        width: 100%;
    }

    canvas {
        max-height: 500px; /* Limit the height of the canvas to 500px */
        max-width: 100%; /* Limit the width of the canvas to the modal width */
        width: auto; /* Allow the width to scale based on the aspect ratio */
        height: auto; /* Allow the height to scale based on the aspect ratio */
    }

</style>