
class EmailTemplate {

    static APPOINTMENT_CONFIRMATION = 'AppointmentConfirmation'

    static APPOINTMENT_CANCELLED = 'AppointmentCancelled'

    static TASK_STATE_CHANGED = 'StateChange'

    static UPDATE_DOCUMENTATION = 'UpdateDocumentation'

    static APPT_CFM_INSTALLATION = 'ApptCfmInstallation'

    static APPT_CFM_INSPECTION = 'ApptCfmInspection'

    static APPT_CFM_TECHNICIAN = 'ApptCfmTechnician'

    static APPT_CFM_TICKET = 'ApptCfmTicket'

    static FORM_TO_PDF_SIGN_INSPECTION = 'FormToPdfSignInspection'

}

export default EmailTemplate
