<template>
    <div class="row" style="width: 100%; margin-left: 0px; margin-right: 0px;">
        <!-- LEFT COLUMN -->
        <div :class="getColumnWidth.first">
            <portal-target :name="targetAreaOne"></portal-target>
            <portal-target v-if="showAreaTwoInColumnOne" :name="targetAreaTwo"></portal-target>
        </div>

        <!-- MIDDLE COLUMN -->
        <div :class="getColumnWidth.second">
            <portal-target v-if="showAreaTwoInColumnTwo" :name="targetAreaTwo"></portal-target>
            <portal-target v-if="showAreaThreeInColumnTwo" :name="targetAreaThree"></portal-target>
            <portal-target v-if="showAreaFourInColumnTwo" :name="targetAreaFour"></portal-target>
        </div>

        <!-- RIGHT COLUMN -->
        <div :class="getColumnWidth.third" v-if="showColumnThree">
            <portal-target v-if="showAreaThreeInColumnThree" :name="targetAreaThree"></portal-target>
            <portal-target v-if="showAreaFourInColumnThree" :name="targetAreaFour"></portal-target>
        </div>

    </div>
</template>

<script>

export default {
    name: 'DynamicGridLayout',
    
    data() {
        return {
            //
        }
    },

    props: {
        selectedLayout: {
            type: String,
            required: true
        },
        showAreaTwo: {
            type: Boolean,
            required: true
        },
        showAreaFour: {
            type: Boolean,
            required: true
        },
        uniqueTargetName: {
            type: String,
            required: true
        }
    },

    computed: {
        currentMedia(){
            return this.$root.$children[0].currentMedia;
        },

        showColumnThree(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [false, true], // showAreaTwo = false; showAreaFour: [false, true]
                    [false, true]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    [false, true],  // showAreaTwo = false; showAreaFour: [false, true]
                    [true, true] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        showAreaTwoInColumnOne(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [false, false], // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [   
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [true, true] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        showAreaTwoInColumnTwo(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [false, false], // showAreaTwo = false; showAreaFour: [false, true]
                    [true, true]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [true, true] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        showAreaThreeInColumnTwo(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [true, true], // showAreaTwo = false; showAreaFour: [false, true]
                    [true, true]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    [true, true],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, true] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [true, true],  // showAreaTwo = false; showAreaFour: [false, true]
                    [true, true] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        showAreaFourInColumnTwo(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [false, false], // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [false, true],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, true] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        showAreaThreeInColumnThree(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [false, false], // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [true, false] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        showAreaFourInColumnThree(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [false, true], // showAreaTwo = false; showAreaFour: [false, true]
                    [false, true]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    [false, true],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, true] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [false, false],  // showAreaTwo = false; showAreaFour: [false, true]
                    [false, false] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        getColumnWidth(){
            const option666 = {
                first: 'col-sm-6',
                second: 'col-sm-6',
                third: 'col-sm-6',
            }

            const option444 = {
                first: 'col-sm-4',
                second: 'col-sm-4',
                third: 'col-sm-4',
            }

            const option336 = {
                first: 'col-sm-3',
                second: 'col-sm-3',
                third: 'col-sm-6',
            }

            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    [option666, option336], // showAreaTwo = false; showAreaFour: [false, true]
                    [option666, option336]  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    [option666, option444],  // showAreaTwo = false; showAreaFour: [false, true]
                    [option444, option444] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    [option666, option666],  // showAreaTwo = false; showAreaFour: [false, true]
                    [option666, option666] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            return this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
        },

        areaOneHeight(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    ['full', 'full'], // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'full']  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    ['full', 'full'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'full'] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    ['full', 'full'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['half', 'half'] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            const height = this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
            this.$emit('areaOneHeight', height);
            return height;
        },

        areaTwoHeight(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    ['full', 'full'], // showAreaTwo = false; showAreaFour: [false, true]
                    ['half', 'half']  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    ['full', 'full'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'half'] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    ['full', 'full'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['half', 'half'] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            const height = this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
            this.$emit('areaTwoHeight', height);
            return height;
        },

        areaThreeHeight(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    ['full', 'full'], // showAreaTwo = false; showAreaFour: [false, true]
                    ['half', 'half']  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    ['full', 'full'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'half'] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    ['full', 'half'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'half'] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            const height = this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
            this.$emit('areaThreeHeight', height);
            return height;
        },

        areaFourHeight(){
            const combinationMatrix = [
                // selectedLayout = 'layout0'
                [
                    ['full', 'full'], // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'full']  // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout1'
                [
                    ['full', 'full'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'full'] // showAreaTwo = true;  showAreaFour: [false, true]
                ],
                // selectedLayout = 'layout2'
                [
                    ['full', 'half'],  // showAreaTwo = false; showAreaFour: [false, true]
                    ['full', 'half'] // showAreaTwo = true;  showAreaFour: [false, true]
                ]
            ];
            const height = this.getCombinationResult(combinationMatrix, this.selectedLayout, this.showAreaTwo, this.showAreaFour);
            this.$emit('areaFourHeight', height);
            return height;
        },

        renderAreaOne(){
            return true;
        },

        renderAreaTwo(){
            return this.showAreaTwo;
        },

        renderAreaThree(){
            return true;
        },

        renderAreaFour(){
            return this.showAreaFour;
        },

        targetAreaOne(){
            return this.uniqueTargetName + '-area-one';
        },

        targetAreaTwo(){
            return this.uniqueTargetName + '-area-two';
        },

        targetAreaThree(){
            return this.uniqueTargetName + '-area-three';
        },

        targetAreaFour(){
            return this.uniqueTargetName + '-area-four';
        },
    },

    methods: {
        getCombinationResult(combinationMatrix, selectedLayout, showAreaTwo, showAreaFour){ 
            let layoutIndex = selectedLayout == 'layout0' ? 0 : selectedLayout == 'layout1' ? 1 : 2;
            return combinationMatrix[layoutIndex][showAreaTwo ? 1 : 0][showAreaFour ? 1 : 0];
        },
    },

    watch: {
        selectedLayout:{
            immediate: true,
            handler() {
                this.areaOneHeight;
                this.areaTwoHeight;
                this.areaThreeHeight;
                this.areaFourHeight;
            }
        },
        showAreaTwo: {
            immediate: true,
            handler() {
                this.areaOneHeight;
                this.areaTwoHeight;
                this.areaThreeHeight;
                this.areaFourHeight;
            }
        },
        showAreaFour: {
            immediate: true,
            handler() {
                this.areaOneHeight;
                this.areaTwoHeight;
                this.areaThreeHeight;
                this.areaFourHeight;
            }
        }
    }
}

</script>