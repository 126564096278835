<template>
    <div id="app" @click.stop="globalBodyClick" style="height: 100vh;">
        <div class="authenticated" v-if="isAuthenticated && !isCustomerPortal"> <!--&& isReady -->
            <TopMenu ref="topMenuRef"
                :media="currentMedia"
                @toggleSideBar="toggleSideBar"
                @logRunningFunctions="logRunningFunctions"
            />

            <LeftMenu
                :media="currentMedia"
            />

            <div id="main" class="content-wrapper" :class="changeBackgroundColor" @scroll="handleScroll"><!--TODO: Improve bad practice-->
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div :class="getHeaderWidth()">
                                <h1 class="m-0 text-dark">
                                    <portal-target name="page-title"></portal-target>
                                </h1>
                            </div>
                            <div :class="getHeaderWidth(true)">
                                <div class="actions">
                                    <portal-target name="page-actions"></portal-target>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="content">
                    <router-view></router-view>
                </section>
            </div>
            <portal-target name="semantic-ui-vue"></portal-target>
            <portal-target name="semantic-ui-modal"></portal-target>
        </div>
        <div v-else-if="!isAuthenticated">
            <section class="content">
                <router-view></router-view>
            </section>
        </div>
        <div v-else-if="isCustomerPortal">
            <section class="content">
                <router-view></router-view>
            </section>
        </div>
        <div v-else>
            <section class="content">
                <TableLoader />
            </section>
        </div>
        <footer v-if="isAuthenticated && isReady" class="main-footer">
            <div v-if="!compressFooter" class="float-right d-none d-sm-inline">
                &copy; 2021 <a href="https://fiberlan.dk" target="_blank">FiberLAN</a>
                &bull;
                <span v-if="appEnv == 'production'">FiberTeam v{{ appVersion }} </span>
                <span v-else>FiberTeam Build {{ buildVersion }}</span>
            </div>
            <span v-if="dataProviderRateLimitRemaining != 1001 && !compressFooter">
                Pilotbi forbrug:
                {{ dataProviderRateLimit - dataProviderRateLimitRemaining }}/{{ dataProviderRateLimit }}
                Nulstilles om {{ rateLimitResetsIn }}
            </span>
            <div style="font-size:13px ;margin-top:-8px" v-if="compressFooter">
                &copy; 2021 <a href="https://fiberlan.dk" target="_blank">FiberLAN</a>
                &bull;
                <span v-if="appEnv == 'production'">FiberTeam v{{ appVersion }} </span>
                <span v-else>FiberTeam Build {{ buildVersion }}</span>
            </div>
            <div style="font-size:13px ;margin-top:-6px; margin-bottom:-10px;" v-if="compressFooter">
                <span v-if="dataProviderRateLimitRemaining != 1001">
                    Pilotbi forbrug:
                    {{ dataProviderRateLimit - dataProviderRateLimitRemaining }}/{{ dataProviderRateLimit }}
                    Nulstilles om {{ rateLimitResetsIn }}
                </span>
            </div>
        </footer>
        <a :href="hiddenLinkHref" target="_blank" ref="hiddenLink" style="visibility: hidden;"></a>
    </div>
</template>

<script>
import { auth, db } from './firebase'
// import BreadcrumbsWithChevrons from './components/BreadcrumbsWithChevrons'
import TopMenu from './components/Global/TopMenu'
import LeftMenu from './components/Global/LeftMenu'
import TableLoader from './components/TableLoader'
import EventBus from './EventBus'
import swal from 'sweetalert'
import UserRoles from './lib/Enums/UserRoles'
import * as Sentry from "@sentry/browser";
import { analytics } from './firebase'

export default {
    name: 'App',
    mixins: [analytics],

    enums: {
        UserRoles
    },

    data() {
        return {
            appEnv: null,
            appVersion: null,
            buildVersion: null,
            isReady: false,
            dataProviderRateLimitRemaining: 1001, // Defaults to 1001
            dataProviderRateLimit: 1000,
            hiddenLinkHref: '',
            firebaseUser: null,
            ivl: null,
            rateLimitResetsIn: null,
            // longestRunningFunctionTime: null,
            runningFunctions: {},
            backgroundColor: '',
            orientationChange: false,
            userMeta: {},
            showScrollToTopButton: false,
        }
    },
    
    components: {
        // BreadcrumbsWithChevrons,
        TopMenu,
        LeftMenu,
        TableLoader
    },

    computed: {
        user() {
            return auth.currentUser
        },
        isAuthenticated() {
            return auth.currentUser !== null
        },
        isCustomerPortal() {
            if (this.$route?.path && this.$route.path.includes('/kunde')) return true
            return false
        },
        isAdmin() {
            try {
                return this.$store.state.userProfile.Roles.includes(UserRoles.USER_ADMIN)
            }
            catch {
                return false
            }
        },
        isSuperAdmin() {
            try {
                return this.$store.state.userProfile.Roles.includes(UserRoles.SUPER_ADMIN)
            }
            catch {
                return false
            }
        },

        currentMedia(){
            this.orientationChange
            let type = 'Desktop'
            let orientation = 'Landscape'
            let os = 'unknown'

            const userAgent = navigator.userAgent || window.opera;
            if (/windows/i.test(userAgent)) {
                os = 'Windows'
            }
            if (/macintosh|mac os x/i.test(userAgent)) {
                os = 'MacOS'
            }
            if (/linux/i.test(userAgent)) {
                os = 'Linux'
            }
            if (/windows phone/i.test(userAgent)) {
                os = 'Windows Phone'
            }
            if (/android/i.test(userAgent)){
                os = 'Android'
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                os = 'iOS'
            }
            if (['Android', 'iOS', 'Windows Phone'].includes(os)){
                if (window.outerHeight <= 991 && window.outerWidth <= 991){ // Mobile
                    if (window.screen.orientation.angle == 0){
                        type = 'Mobile'
                        orientation = 'Portrait'
                    } else {
                        type = 'Mobile'
                        orientation = 'Landscape'
                    }
                } else if (window.outerHeight <= 1366 && window.outerWidth <= 1366){ // Tablet
                    if (window.screen.orientation.angle == 0){
                        type = 'Tablet'
                        orientation = 'Portrait'
                    } else {
                        type = 'Tablet'
                        orientation = 'Landscape'
                    }
                } 
            }
            let resolution = '?'
            if (type == 'Desktop'){
                resolution = 'FHD' // Default FHD: 1920x1080
                if (window.screen.availWidth == 3840) resolution = 'UHD' // UHD: 3840x2160
                if (window.screen.availWidth == 3440) resolution = 'UWQHD' // UWQHD: 3440x1440
                if (window.screen.availWidth == 2560) resolution = 'QHD' // QHD: 2560x1440
            } else {
                resolution = `${window.screen.availWidth}x${window.screen.availHeight}`
            }

            let media = {type: type, orientation: orientation, screenRes: resolution, os: os}

            if (media.os === "Windows"){
                console.log("Current Media", media)
            } else {
                console.log("Current Media",JSON.stringify(media))
            }
            return media
        },

        compressFooter(){
            if (this.currentMedia.type == 'Mobile') return true
            return false
        },

        changeBackgroundColor() {
            if (!this.$route?.path) return ''
            if (this.$route.path.split('/').length > 2) return this.backgroundColor
            return ''
        },

        anyFunctionIsRunning() {
            for (let funcName of Object.keys(this.runningFunctions)) {
                if (this.runningFunctions[funcName].isActive) return true
            }
            return false
        },

        // longestRunningFunctionStartTime() {
        //     let firstTimeStamp
        //     for (let funcName of Object.keys(this.runningFunctions)) {
        //         if (this.runningFunctions[funcName].activated) {
        //             let timeStamp
        //             if (Array.isArray(this.runningFunctions[funcName].activated)){
        //                 timeStamp = Math.max(...this.runningFunctions[funcName].activated)
        //             } else {
        //                 timeStamp = this.runningFunctions[funcName].activated
        //             }
        //             if (timeStamp < firstTimeStamp) firstTimeStamp = timeStamp
        //         }
        //     }
        //     return firstTimeStamp
        // }
    },

    watch: {
        user: {
            immediate: false,
            handler() {
                this.bindUser()
            }
        },
        firebaseUser: {
            immediate: false,
            handler(newValue, oldValue) {
                //If property 'TokenLastRevoked' updates, then logout user
                if (oldValue && newValue && oldValue.TokenLastRevoked != newValue.TokenLastRevoked) {
                    this.$store.dispatch('logout', {redirect: '/login?tokenRevoked=true'})
                }
            }
        },
    },

    methods: {
        handleScroll(e) {
            const scrollTop = e.target.scrollTop; // Current vertical scroll position
            const windowHeight = window.innerHeight; // Height of the viewport
            const documentHeight = e.target.scrollHeight; // Total height of the document

            // Calculate how far down the user has scrolled as a percentage
            const scrollPercentage = (scrollTop / (documentHeight - windowHeight)) * 100;

            // Show button if scrolled more than 25% or 400px
            if (scrollTop > 400 || scrollPercentage > 25) {
                this.showScrollToTopButton = true;
            } else {
                this.showScrollToTopButton = false;
            }
        },

        getHeaderWidth(actions = false){
            if (this.$route.name == 'ProjectListView'){
                if (actions) return 'col-sm-8'
                else return 'col-sm-4'
            }

            if (this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Landscape'){
                if (actions) return 'col-sm-4'
                else return 'col-sm-8'
            } else if (this.currentMedia.type == 'Tablet'){
                if (actions) return 'col-sm-7'
                else return 'col-sm-5'
            }
            else {
                if (this.$route.name == 'FormToPdf'){
                    if (actions) return 'col-sm-7'
                    else return 'col-sm-5'
                }


                if (actions) return 'col-sm-6'
                else return 'col-sm-6'
            }
        },

        toggleOrientationChange(){
            this.orientationChange = !this.orientationChange
        },

        // scrollToTop() {            
        //     try {
        //         this.$refs.topMenuRef.scrollIntoView({ behavior: 'smooth' })
        //         // eslint-disable-next-line
        //     } catch(e) {}
        // },

        globalBodyClick() {
            if (this.currentMedia.type != 'Desktop' && document.body.classList.contains('sidebar-open')) {
                this.toggleSideBar()
            }

            EventBus.$emit('close-bs-dropdown')
        },

        toggleSideBar() {
            if (this.currentMedia.type != 'Desktop') {
                if (document.body.classList.contains('sidebar-collapse')) {
                    document.body.classList.add('sidebar-open')
                    document.body.classList.remove('sidebar-closed')
                } else {
                    document.body.classList.remove('sidebar-open')
                    document.body.classList.add('sidebar-closed')
                    EventBus.$emit('sidebar-clear-submenu-expanders')
                }
            }
            document.body.classList.toggle('sidebar-collapse')
        },

        bindUser() {
            if (this.user) {
                Sentry.setUser({
                    ...this.user,
                    username: this.user.displayName
                })
                this.$bind('firebaseUser', db.collection('Users').doc(this.user.email))
                this.userMeta = {
                    email: this.user.email,
                    displayName: this.user.displayName,
                }
            } else {
                Sentry.configureScope(scope => scope.setUser(null));
                if(this.firebaseUser){
                    this.$unbind('firebaseUser')
                    this.firebaseUser = null
                }
            }
        },

        formatDuration(durationInMs, includeMiliseconds = false, includeHours = false) {
            if (!durationInMs) return null
            let durationInS = Math.floor(durationInMs / 1000)
            let msStr = `000${durationInMs % 1000}`.slice(-3)
            let secStr = `00${durationInS % 60}`.slice(-2)
            let min = Math.floor(durationInS / 60)
            if (!includeHours) {
                let str = `${min}:${secStr}`
                if (includeMiliseconds){
                    str += `.${msStr}`
                }
                return str
            }
            let h = (min - (min % 60)) / 60
            min = min % 60
            let minStr = `00${min}`.slice(-2)
            let str = `${h}:${minStr}:${secStr}`
            if (includeMiliseconds) {
                str += `.${msStr}`
            }
            return str
        },

        updateTimer(payload) {
            if (this.ivl){
                return
            }
            if(payload){
                this.ivl = setInterval(()=> {
                    let currentTime = new Date()
                    let resetTime = payload.reset
                    let diff = Math.ceil((resetTime - currentTime))
                    if (diff <= 0) {
                        clearInterval(this.ivl)
                        this.ivl = null
                        this.rateLimitResetsIn = `0:00`
                    } else {
                        this.rateLimitResetsIn = this.formatDuration(diff)
                    }
                }, 1000)
            }
            
        },

        setFunctionActivity(functionName, isActive, allowMultiple = true) {
            // this.updateLongestRunningFunctionTime()
            let now = performance.now()
            if (isActive) {
                if (allowMultiple) {
                    if (this.runningFunctions[functionName]) {
                        this.$set(this.runningFunctions, functionName, {
                            isActive: this.runningFunctions[functionName].isActive + 1,
                            activated: [...this.runningFunctions[functionName].activated, now]
                        })
                    } else {
                        this.$set(this.runningFunctions, functionName, {
                            isActive: 1,
                            activated: [now]
                        })
                    }
                }
                else if (this.runningFunctions[functionName] && this.runningFunctions[functionName].isActive){
                    console.error(`Attempted to log starting function '${functionName}' but the function has already been running for ${now - this.runningFunctions[functionName].activated}ms`)
                } else {
                    this.$set(this.runningFunctions, functionName, {
                        isActive,
                        activated: now
                    })
                }
            } else {
                if (this.runningFunctions[functionName] && this.runningFunctions[functionName].activated){
                    let time
                    if (allowMultiple) {
                        time = now - this.runningFunctions[functionName].activated[0]
                        this.$set(this.runningFunctions, functionName, {
                            isActive: this.runningFunctions[functionName].isActive - 1,
                            activated: this.runningFunctions[functionName].activated.slice(1)
                        })
                        if (this.runningFunctions[functionName].isActive < 0) {
                            console.error(`Attempted to log finishing function '${functionName}' but the function had 0 or less instances running`)
                            this.$set(this.runningFunctions, functionName, {
                                isActive: 0,
                                activated: []
                            })
                        }
                        analytics.logEvent('function_excecuted', {function_name: functionName, time})
                    } else {
                        time = now - this.runningFunctions[functionName].activated
                        this.$set(this.runningFunctions, functionName, {isActive})
                        analytics.logEvent('function_excecuted', {function_name: functionName, time})
                    }
                } else {
                    console.error(`Attempted to log finishing function '${functionName}' but the function was not logged started`)
                    // this.$set(this.runningFunctions, functionName, {isActive})
                }
            }
        },

        // updateLongestRunningFunctionTime() {
        //     let now = performance.now()
        //     this.longestRunningFunctionTime = this.formatDuration(now - this.longestRunningFunctionStartTime)
        // },

        logRunningFunctions() {
            let arr = []
            for (let functionName of Object.keys(this.runningFunctions)) {
                if (this.runningFunctions[functionName].isActive){
                    arr.push({
                        functionName,
                        activated: Math.min(...this.runningFunctions[functionName].activated),
                        isActive: this.runningFunctions[functionName].isActive
                    })
                }
            }
            console.table(arr)
        },

        loadApiMetaData() {
            return new Promise((resolve, reject) => {
                if (this.user) {
                    let maxRetryTimeout = 10;
                    let interval = setInterval(() => {
                        if (maxRetryTimeout <= 0) {
                            clearInterval(interval);
                            reject(new Error('Failed to load API meta data'));
                        }
                        maxRetryTimeout--;
                        EventBus.$emit('load-api-meta-data');
                    }, 1000);

                    EventBus.$on('api-meta-data-loaded', () => {
                        clearInterval(interval);
                        EventBus.$off('api-meta-data-loaded');
                        resolve(); // Resolve the promise when the event is triggered
                    });
                } else {
                    resolve(); // Resolve immediately if the user is not logged in
                }
            })
        },

        handleResize() {
            this.toggleOrientationChange();
        },

    },

    async beforeMount() {
        console.log('This Web app is developed by, and property of FiberLAN A/S')

        EventBus.$on('update-project-background-color', (payload) => {this.backgroundColor = payload})
        EventBus.$on('api-meta-data-loaded', (payload) => {
            if (payload === true) {
                console.log("App is ready")
                this.isReady = true
            }
        })

        EventBus.$on('data-provider-api-ratelimit-usage', (payload) => {
            const { remaining, limit, reset } = payload
            if (!limit) return
            this.dataProviderRateLimitRemaining = remaining
            this.dataProviderRateLimit = limit
            this.dataProviderRateLimitReset = reset
            this.updateTimer(payload)
        })

        EventBus.$on('link-to-external-url', async (payload) => {
            // console.log(payload)
            await (this.hiddenLinkHref = payload)
            // console.log(this.$refs['hiddenLink'])
            this.$refs['hiddenLink'].click()
        })

        EventBus.$on('login-failure', (data) => {
            swal('Login fejlede', JSON.stringify(data), 'error')
        })

        EventBus.$on('function-activity', (data) => {
            // console.log(`Function '${data.functionName}' is ${data.isActive ? 'started' : 'finished'}`)
            this.setFunctionActivity(data.functionName, data.isActive)
        })

        this.appEnv = process.env.VUE_APP_ENV
        this.appVersion = process.env.VUE_APP_VERSION
        this.buildVersion = process.env.VUE_APP_GIT_HASH
    },

    created() {
        window.addEventListener("resize", this.handleResize);
    },
    
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },

    async mounted() {
        EventBus.$emit('function-activity', {functionName: 'App_mounted', isActive: true})

        this.bindUser()
        
        EventBus.$emit('function-activity', {functionName: 'App_mounted', isActive: false})

    },
}
</script>

<style>
@import '@/assets/styles/colours.css';
@import '@/assets/styles/FiberLAN.css';
/*#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    padding-top: 60px;
}
#main {
    padding-top: 50px;
}*/

#app {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full height of the viewport */
}

html, body {
    font-size: 14px;
    font-family: "Source Sans Pro",Lato,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
    /* font-family: "FiberLAN Regular" !important; */
    overflow-x: hidden;
    overflow-y: hidden;
}

#main.content-wrapper {
    overflow-y: scroll;
    height: calc(100vh - 205px);
}

.ui .item {
    font-size: 14px !important;
}

.ui.popup {
    z-index: 9999;
}

.main-footer {
    color: #a4adb5 !important;
}

</style>
